export const NAV = 'NAV';
export const MACRO = "macro";
export const MACRO_PATH = "/" + MACRO;
export const SCREENER = "screener";
export const SCREENER_PATH = "/" + SCREENER;
export const PORTFOLIO = "portfolio";
export const PORTFOLIO_PATH = "/" + PORTFOLIO;
export const LINKEDIN = "linkedin";
export const LINKEDIN_PATH = "/" + LINKEDIN;
export const EXPERIMENTAL = "experimental";
export const EXPERIMENTAL_PATH = "/" + EXPERIMENTAL;
export const FINDATA_PATH = "/findata";
export const SERIES_DEBUGGER_PATH = "/series-debugger";
export const NAV_PATH = PORTFOLIO_PATH + '/' + NAV;
export const POSITIONS = 'POSITIONS';
export const POSITIONS_PATH = PORTFOLIO_PATH + '/' + POSITIONS;
export const DASHBOARD = 'DASHBOARD';
export const DASHBOARDS_PATH = PORTFOLIO_PATH + '/' + DASHBOARD;
export const FACTORS = 'FACTORS';
export const FACTORS_PATH = PORTFOLIO_PATH + '/' + FACTORS;
export const INTRADAY = 'INTRADAY';
export const INTRADAY_PATH = PORTFOLIO_PATH + '/' + INTRADAY;

export const CHART = 'CHART';
export const CHART_PATH = '/' + CHART;