import {reducePxValue} from "./utilityFunctions";
import {useState} from "react";
import highChartToSvgDashStyleConvertor from "./HighChartToSvgDashStyleConvertor";
import {Checkbox} from "antd";
import {MdOutlineDoubleArrow, MdStackedLineChart} from "react-icons/md";

const SVG_LEGEND_LINE_CODE = "M 0 10 L 16 10";
const SVG_DIAMOND_CODE = "M 8 5.5 L 12.6 10 L 8 14.7 L 3.5 10 L 8 5.5 Z";
export const CustomLegend = ({
                                 series,
                                 toggleSeries,
                                 chartHeight,
                                 onKeyUp,
                                 onKeyDown,
                                 width,
                                 setHoverForSeries,
                                 ssMode = false
                             }) => {

    return <div
        className={'divWithCustomScroll'}
        style={{height: reducePxValue(chartHeight, 60), width: width + 'px'}}
        onKeyUp={onKeyUp} onKeyDown={onKeyDown} tabIndex="0">
        {series.map((s, i) => {
                if (ssMode && !(s.visible)) return <></>
                else return <LegendItem key={i} index={i} series={s} toggleSeries={toggleSeries}
                                        setHoverForSeries={setHoverForSeries}/>
            }
        )
        }
    </div>
}

export const CustomLegendSmallSizeOptimized = ({
                                                   series,
                                                   toggleSeries,
                                                   chartHeight,
                                                   onKeyUp,
                                                   onKeyDown,
                                                   width,
                                                   setHoverForSeries,
                                                   setCombineAxes,
                                                   setDynamicLag,
                                                   combineAxes,
                                                   dynLag,
                                                   ssMode = false,
                                                    copyScreenshot
                                               }) => {
    return <div
        className={'limitedSizeDivWithCustomScroll'}
        style={{height: reducePxValue(chartHeight, 0), width: width + 'px'}}
        onKeyUp={onKeyUp} onKeyDown={onKeyDown} tabIndex="0">

        <Checkbox
            onChange={(e) => setCombineAxes(e.target.checked)}
            checked={combineAxes}
        />
        <MdStackedLineChart
            style={{fontSize: 20, color: "#50C878", justifySelf: 'left', marginLeft: '8px'}}
        />
        <input
            type="text"
            style={{width: '50px', height: '18px', alignSelf: 'center', border: '1px solid #ddd'}}
            onChange={e => {
                // Ensure the value is a number before setting it
                const translatedNumber = Number(e.target.value);
                if (!isNaN(translatedNumber)) {
                    setDynamicLag(translatedNumber)
                }
            }}
            defaultValue={dynLag}
        />
        <MdOutlineDoubleArrow style={{fontSize: 25}}/>
        <a className={'button-factors-small'} onClick={copyScreenshot}>Screenshot</a>
        {series.map((s, i) => {
                if (ssMode && !(s.visible)) return <></>
                else return <LegendItem key={i} index={i} series={s} toggleSeries={toggleSeries}
                                        setHoverForSeries={setHoverForSeries}/>
            }
        )
        }
    </div>
};

const LegendItem = ({series, index, toggleSeries, setHoverForSeries}) => {
    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = (index) => {
        setIsHovered(true);
        setHoverForSeries(index, true);
    }

    const onMouseLeave = (index) => {
        setIsHovered(false);
        setHoverForSeries(index, false);
    }

    if (!series.showInLegend) return null;
    const isActive = series.visible;
    const textColor = isActive ? 'black' : '#666666';
    const underlineStyle = isActive ? 'underline' : 'none';
    let colourToUse = isHovered ? 'black' : textColor;


    return (
        <span key={index} onClick={() => toggleSeries(index)} className="textSpan"
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={() => onMouseLeave(index)}
              title={series.name}
        >
            <div>
                {getSvgElementForSeries(series)}
            </div>
            <div>
            <text className="legendText" style={{
                color: colourToUse,
                textDecoration: underlineStyle
            }}>{series.name}</text>
            </div>
        </span>
    )
}
const getSvgElementForSeries = series => {
    let isActive = true;
    let legendColor = isActive ? series.color : '#666666';
    const isMarkerSeries = series && series.marker && series.marker.enabled;
    const strokeStyle = highChartToSvgDashStyleConvertor(series.dashStyle);
    const strokeWidth = series.lineWidth;
    return (
        <svg className="legendSVG">
            <path fill="none" className="highcharts-graph" strokeWidth={strokeWidth}
                  strokeDasharray={strokeStyle}
                  d={SVG_LEGEND_LINE_CODE} stroke={legendColor}></path>
            {isMarkerSeries && (
                <path fill={legendColor} d={SVG_DIAMOND_CODE} stroke="#ffffff" strokeWidth="0"
                      opacity="1"></path>
            )}
        </svg>
    )
}