import {useContext, useEffect, useRef, useState} from "react";
import {ListsDataContext} from "../commonContexts/ListsProvider";
import {monitor} from "../../../event_handler/KeyPressMonitor";
import {Button, ConfigProvider, InputNumber, Select} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {DataLagDisplay} from "./DataLagDisplay";
import {ViewControlIcons} from "./ViewControlIcons";
import {Tooltip} from "react-tooltip";
import {MdInfo} from "react-icons/md";
import {Pagination, Stack} from "@mui/material";

export const WATCHLISTS = "WATCHLISTS";
export const ALL = 'ALL';
export const LONG = 'LONG';
export const SHORT = 'SHORT';

const ITEMS_PER_PAGE = 40;

const PaginatedTable = ({list, index, setIndex}) => {
    // Gets a list of ticker names, and returns a paginated list of the ticker id and corresponding index:
    const [page, setPage] = useState(Math.floor(index/ITEMS_PER_PAGE) + 1);
    const changePage = (event, page) => setPage(page);

    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = page * ITEMS_PER_PAGE;

    return (<Stack spacing={2}>
        <div style={{
            maxHeight: "800px",  // you can adjust this value according to your needs
            overflowY: "auto"   // makes vertical scrollable when the content exceeds the maxHeight
        }}>
            <table>
                <thead>
                <tr>
                    <th>Index</th>
                    <th>Ticker</th>
                </tr>
                </thead>
                <tbody>
                {list.slice(startIndex, endIndex).map((item, index) => (
                    <tr key={index}>
                        <td>{startIndex + index + 1}</td>
                        <td
                            style={{
                                cursor: 'pointer',
                                color: 'blue',
                                textDecoration: 'underline'
                            }}
                            onClick={() => setIndex(startIndex + index)}
                        >{item}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <Pagination count={Math.ceil(list?.length/ITEMS_PER_PAGE)} page={page} onChange={changePage} />
        </div>
    </Stack>);


}

export const ListSelectionBar = ({activeTick, brand, showDataLag, showViewControls, tooltipMode = false}) => {

    const {
        watchLists,
        loadingWatchList,
        activeList,
        setActiveList,
        goToNext,
        goToPrev,
        goToIndex,
        activeSelector,
        setActiveSelector,
        index,
        listLength,
        list
    } = useContext(ListsDataContext);

    const listSelectRef = useRef(null);

    const selectTexColor = activeSelector === WATCHLISTS ? " #1677ff" : "white";


    const [isOpen, setIsOpen] = useState(false);
    const [tickerListIsOpen, setTickerListIsOpen] = useState(false);

    useEffect(() => {
        if (!isOpen) setTickerListIsOpen(false);
    }, [isOpen])


    const barItems = (<>
        <MdInfo style={{color: 'white'}} id='tickers-list-anchor-element' onClick={() => setTickerListIsOpen(prev => !prev)}/>
        <Tooltip
            anchorSelect={'#tickers-list-anchor-element'}
            place='bottom'
            variant='light'
            clickable={true}
            isOpen={tickerListIsOpen}
            role={'dialog'}
            style={{zIndex: 1000}}
            opacity={1}
        >
        <PaginatedTable list={list} index={index} setIndex={goToIndex} />
        </Tooltip>
        <Button className={'navigation-list-selection-button'} type={'primary'} shape={'circle'}
                onClick={goToPrev} icon={<LeftOutlined/>}/>
        <span
            style={{
                marginRight: 10,
                color: 'white'
            }}> {<InputNumber min={1} max={listLength} value={index}
                              onPressEnter={(value) => {
                                  const newNumber = Number(value.target?.value) - 1;
                                  if(newNumber >=0 && newNumber <= listLength) goToIndex(newNumber);
                              }}
                              changeOnBlur={true} changeOnWheel={false}/>}/{listLength ?? 0}
            </span>
        <Button className={'navigation-list-selection-button'} type={'primary'} shape={'circle'}
                onClick={goToNext} icon={<RightOutlined/>}/>
        <ConfigProvider
            theme={{
                components: {
                    Select: {
                        optionSelectedBg: selectTexColor,
                        selectorBg: selectTexColor
                    },
                }
            }}
        >
            <Select
                ref={listSelectRef}
                style={{width: 240}}
                showSearch
                onFocus={() => monitor.enableHotKeys(false)}
                onBlur={() => monitor.enableHotKeys(true)}
                loading={loadingWatchList}
                defaultValue={{
                    label: activeList,
                    value: activeList
                }}
                value={{
                    label: activeList,
                    value: activeList
                }}
                onSelect={(value) => {
                    setActiveList(value);
                    if (listSelectRef.current) {
                        listSelectRef.current.blur();
                    }
                    setActiveSelector(WATCHLISTS);
                }
                }
                optionFilterProp={"label"}
                options={watchLists}/>
        </ConfigProvider>
        <div>
            <Button type={activeSelector === ALL ? "primary" : undefined} style={{marginLeft: 10, marginTop: '0px'}}
                    onClick={() => {
                        setActiveSelector(ALL);
                    }}>All Positions</Button>
            <Button type={activeSelector === LONG ? "primary" : undefined} style={{marginLeft: 5}}
                    onClick={() => {
                        setActiveSelector(LONG);
                    }}>Long Positions</Button>
            <Button type={activeSelector === SHORT ? "primary" : undefined} style={{marginLeft: 5}}
                    onClick={() => {
                        setActiveSelector(SHORT);
                    }}>Short Positions</Button>
        </div>
        {showDataLag ? <DataLagDisplay activeTick={activeTick} brand={brand} /> : <></>}
        {showViewControls ? <ViewControlIcons/> : <></>}
    </>);

    return (<>
        {tooltipMode && <a
            id="positions-anchor-element" className="button-factors-small"
            onClick={() => setIsOpen(prev => !prev)}
        >
            Pos
        </a>}
        {tooltipMode &&
            <>
                <Tooltip
                    anchorSelect={"#positions-anchor-element"}
                    place={'bottom'}
                    variant={'light'}
                    clickable={true}
                    closeEvents={[ondblclick]}
                    role={'dialog'}
                    style={{zIndex: 1000}}
                    opacity={1}
                    isOpen={isOpen}
                >
        <span className='list-selection-bar'>
            {barItems}
        </span>
                </Tooltip>
                <a className="button-factors-small" onClick={goToPrev}>{'<'}</a>
                <a className="button-factors-small additional-margin-right" onClick={goToNext}>{'>'}</a>
            </>

        }
        {!tooltipMode && <nav className={'list-selection-bar'}>
            {barItems}
        </nav>}

    </>);


}