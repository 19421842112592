import {Col, DatePicker, Row} from "antd";
import * as dayjs from 'dayjs';
import {useEffect, useState} from "react";
import {getLatestPerformanceDate} from "../../../api/data/PortfolioDataProvider";

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import './css/navscreen.css';
import ExposureTable from './ExposureTable';
import PnlTable from './PnlTable';
import SpreadChart from "./SpreadChart";
import PositionTable from "./PositionTable";
import WashsaleTable from "./WashsaleTable";

function NavScreen(props) {
    const [defaultDate, setDefaultDate] = useState(null);

    const onDateChange = (date, dateStr) => {
        props.setReportDate(dateStr);
    }

    useEffect(() => {
        const abortController = new AbortController();
        getLatestPerformanceDate(abortController)
            .then((res) => {
                props.setReportDate(res.data);
                setDefaultDate(res.data);
            })
            .catch((err) => {
                console.error(err);
            })

        return () => abortController.abort();
    }, []);

    return (
        <div id="nav-screen" style={{ marginTop: 50 }}>
            <Row align="middle">
                <Col span={24}>
                    <DatePicker value={props.reportDate ? dayjs(props.reportDate) : dayjs(defaultDate)} onChange={onDateChange} size='large' />
                </Col>
            </Row>
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>PNL</h4>
                    <PnlTable reportDate={props.reportDate} />
                </Col>
                <Col flex={1}>
                    <h4>Exposure</h4>
                    <ExposureTable reportDate={props.reportDate} />
                </Col>
            </Row>
            <hr />
            <Row gutter={16} justify="center">
                <Col flex={1}>
                    <h4>Position List</h4>
                    <PositionTable reportDate={props.reportDate} />
                </Col>
                <Col flex={1}>
                    <h4>Washsale Info</h4>
                    <WashsaleTable reportDate={props.reportDate} />
                </Col>
            </Row>
            <hr />
            <Row gutter={16} justify="center">
                <Col span={24}>
                    <SpreadChart />
                </Col>
            </Row>
        </div>
    )
}

export default NavScreen;