import React, {useEffect, useState} from 'react';
import {Radio, Select} from 'antd';
import {formatNumber, formatPercent, formatCurrency} from '../../utils/FinDataUtils';
import {AgGridReact} from "@ag-grid-community/react";
import { SeriesTypes } from '../../api/data/SeriesTypes';


const defaultSeriesOptions = {
    Rev: { label: "Operational Metrics", options: [{
        value: SeriesTypes.SALES,
        label: "Total Revenue"
    },
    {
        value: SeriesTypes.GROSSPROFIT,
        label: "Gross Profit"
    },
    {
        value: SeriesTypes.EBITDA,
        label: "EBITDA"
    },
    {
        value: SeriesTypes.EPSDILUTED,
        label: "EPS Diluted"
    }] },
    Liq: { label: "Assets & Liabilities", options: [] },
    Fin: { label: "Financials", options: [] }
  };


// Object.entries({
//     [SeriesTypes.SALES]: "Total Revenue",
//     [SeriesTypes.EPSDILUTED]: "EPS Diluted",
//     [SeriesTypes.EBITDA]: "EBITDA",
//     [SeriesTypes.GROSSPROFIT]: "Gross Profit",
//     [SeriesTypes.COGS]: "Cost of Goods Sold",
//     [SeriesTypes.IS_DIVIDENDSPERSHARE]: "Dividends Per Share",
//     [SeriesTypes.FCF]: "Free Cash Flow",
//     [SeriesTypes.DnA]: "Depreciation & Amortization",
//     [SeriesTypes.SGnA]: "Selling, General & Administrative",
//     [SeriesTypes.SALESPERSHARE]: "Sales Per Share"
// }).map(([key, label]) => ({ value: key, label: label }));


const getGroupedSeriesOptions = (lineItems) => {
    if(!lineItems || lineItems.length === 0){
        return Object.values(defaultSeriesOptions);
    }
  const groups = {
    Rev: { label: "Operational Metrics", options: [] },
    Margin: { label: "Profit & Margin", options: [] },
    Liq: { label: "Assets & Liabilities", options: [] },
    Fin: { label: "Financials", options: [] }
  };

  lineItems.forEach(item => {
    if (item.studiogroupid in groups) {
      groups[item.studiogroupid].options.push({
        value: item.line_item_id,
        label: item.name,
      });
    }
  });

  return Object.values(groups).filter(group => group.options.length > 0);
};

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const onSearch = (value) => {
    console.log("onSearch",value);
}

const SelectComponent = ({ percentOption, seriesOption, currSymbol, onPercentChange, onSeriesChange, disabled, error, lineItems }) => {
    const selectClass = error ? 'select-error' : '';
    const groupedOptions = getGroupedSeriesOptions(lineItems);

    return (
        <div style={{ textAlign: 'left', marginBottom: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Select
                className={selectClass}
                size="large"
                style={{ width: 420, marginRight: 10, opacity: disabled ? 0.4 : 1 }}
                placeholder="Select an option"
                showSearch
                filterOption={(input, option) => {
                    try {
                        //ignore option Groups 
                        if (!option.options) {
                            // This is an OptGroup
                            return option.children.toLowerCase().includes(input.toLowerCase());
                        }

                    } catch (error) {
                        console.error('Error in filterOption:', error);
                        return false;
                    }
                }}
                optionFilterProp="children"
                value={seriesOption}
                onChange={onSeriesChange}
                disabled={disabled}
                listHeight={600}
                placement="bottomLeft"
            >
                {groupedOptions.map(group => (
                    <Select.OptGroup key={group.label} label={group.label}>
                        {group.options.map(option => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
            </Select>
            
            <Radio.Group size="large" onChange={onPercentChange} value={percentOption}>
                        
                        <Radio.Button value={'YoY'}>% YoY</Radio.Button>
                        <Radio.Button value={'QoQ'}>% QoQ</Radio.Button>

            </Radio.Group>
        </div>
    );
};


const customCellRenderer = (params) => {
    const value = params.value.value;
    const percentValue = params.value.percentValue;
    const reportType = params.value.reportType;
    const diagonalStyle = reportType === 'E' ? { borderTop: '50px solid rgba(33, 150, 243, 0.15)', borderRight: '74px solid transparent' } : {};

    return (
        <div style={{ height: '50px', width: '75px', backgroundColor: 'rgb(255, 255, 255)' }}>
            <div className="custom-cell">
                <div className="diagonal_cell_border" style={diagonalStyle}></div>
                <div className="top-left">{value}</div>
                <div className="bottom-right">{percentValue}</div>
            </div>
        </div>
    );
};

const getRowClass = (params) => {
    return params.node.rowIndex === 4 ? 'divider-row' : ''; // Row index is zero-based
  };

const getRowHeight = (params) => {
    return params.node.rowIndex === 4 ? 56 : 50;
}

const TableComponent = ({ rowData, columnDefs }) => {
    const cellStyle = params => {
        const rowIndex = params.node.rowIndex;
        const colIndex = params.columnApi.getAllColumns().indexOf(params.column);
        return (rowIndex % 2 === 0 && colIndex % 2 === 0) || (rowIndex % 2 !== 0 && colIndex % 2 !== 0)
            ? { background: '#f0f0f0' }
            : { background: '#ffffff' };
    };

    const onGridReady = params => {
        // Capture the grid API
        const gridApi = params.api;

        // Find column with colId as currentYear
        const allColumns = gridApi.getAllGridColumns();
        const currentYear = (new Date().getFullYear() + 3).toString();
        let targetColumn = allColumns.find(column => column.getColId() === currentYear);
        if (!targetColumn) {
            targetColumn = allColumns[allColumns.length - 3];
        }
        gridApi.ensureColumnVisible(targetColumn, 'end');

    };


    return (
        <div className="ag-grid-findata ag-theme-alpine finmatrix-table" style={{ width: '100%', overflowX: 'hidden' }}>
            <AgGridReact
                headerHeight={30}
                rowData={rowData}
                getRowHeight={getRowHeight}
                columnDefs={columnDefs}
                domLayout='autoHeight'
                getRowClass={getRowClass}
                defaultColDef={{
                    minWidth: 75,
                    maxWidth: 75,
                    // cellStyle: cellStyle,
                }}
                onGridReady={onGridReady}
            />
        </div>
    );
};

const getColumnDefFromPeriod = (period) => {
    return {
        headerName: period,
        field: period,
        width: 55,
        headerClass: "finmatrix-header",
        cellRenderer: customCellRenderer,
        valueGetter: function(params) {
            // Check if the 'name' field is present and not null
            return period in params.data && params.data[period] != null ? params.data[period] : 0;
          }
    }
}

//
const quarterMonthToLabel = (quarterNumber, period_end_date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(period_end_date);
    const month = monthNames[date.getMonth()];
    return `Q${quarterNumber} ${month}`;    
}


//{"fyyear": 2018, "quarter": 1, "quarter_month": 3, "period": "1QFY-2018", "value": 1291, "currency": "USD", "reportType": "reported", "qoq": 12.1, "yoy": 12.1}
//result: q1: { "2018": 1291, "2017": 1291, "2016": 1291, "2015": 1291, "FY": 1291 } q2: 
//each row has all the years - 5 rows for each quarter and fy
const transformDataForAgGrid = (seriesData, pOpt, decimals) => {
    const columns = new Set();
    const fyRow = {};
    const quarterRows = [{},{},{},{}]; //0,1,2,3
    for(let i=0;i<seriesData.length;i++) {
        const row = seriesData[i];
        columns.add(row.fyyear.toString());
        if(row.quarter) {
            const percentValue = pOpt === 'QoQ' ? row.qoq : row.yoy;
            quarterRows[row.quarter - 1][row.fyyear.toString()] = { value: formatNumber(row.value, decimals), percentValue: formatPercent(percentValue), reportType: row.reportType };
            if(row.period_end_date) {
                quarterRows[row.quarter - 1]['label'] = quarterMonthToLabel(row.quarter, row.period_end_date);
            }
        } else {
            fyRow[row.fyyear.toString()] = { value: formatNumber(row.value, decimals), percentValue: formatPercent(row.yoy), reportType: row.reportType }; //qoq will be null here
        }
    }
    fyRow['label'] = 'FY';
    const sortedColumns = Array.from(columns).sort(); //add first column
    sortedColumns.unshift('label');
    return {
        fyRow,
        quarterRows,
        columns: sortedColumns
    }
}

//provided the data will be pushed from above
const FinMatrix = ({ dataState, seriesOption, onSeriesOptionChange, lineItems }) => {
    const { loading, isError, data } = dataState;
    const [percentOption, setPercentOption] = useState('YoY');
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState(null);
    const currency = data && data.company_info && data.company_info.currency
        ? data.company_info.currency : 'USD';
    const lineItemUnit = lineItems?.find(item => item.line_item_id === seriesOption)?.unit;
    const lineItemMagnitude = lineItems?.find(item => item.line_item_id === seriesOption)?.magnitude;
    
    //only recompute if data changes - series change will trigger data change
    useEffect(() => {
        const decimals = lineItemMagnitude == "Abs" ? 2 : 1;
        if (data && data.data && !isError && !loading) {
            const { columns, quarterRows, fyRow } = transformDataForAgGrid(data.data, percentOption, decimals);
            setRowData([ ...quarterRows, fyRow]);
            
            const defaultColumnDefs = columns.map(column => {
                if(column == 'label'){
                    //first column
                    return { headerName: '', headerClass:"finmatrix-header", field: 'label', width: 50, pinned: 'left', cellStyle: {fontWeight: 'bold', fontSize: '12px'}};
                }
                return getColumnDefFromPeriod(column)
            });
            setColumnDefs(defaultColumnDefs);
        }
    }, [percentOption, seriesOption, dataState ]);

    

    const handleSeriesChange = value => {
        onSeriesOptionChange(value);
    };

    const handlePercentChange = e => {
        setPercentOption(e.target.value);
    };

    return (
        <div style={{ marginLeft: '20px', marginBottom: '30px' }}>
            <SelectComponent currSymbol={formatCurrency(currency)} disabled={loading} error={isError} seriesOption={seriesOption} percentOption={percentOption} onPercentChange={handlePercentChange} onSeriesChange={handleSeriesChange} lineItems={lineItems} />
            { columnDefs && <TableComponent rowData={rowData} columnDefs={columnDefs} />}
        </div>
    );
};

export default FinMatrix;