import {AgGridReact} from '@ag-grid-community/react';
import {Alert} from 'antd';
import * as dayjs from 'dayjs';
import {useEffect, useMemo, useRef, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getExposureData, getIntradayExposureData} from "../../../api/data/PortfolioDataProvider";

const accountIdMap = new Map([
    ['shah_v2', {name: 'Shah V2', accountIds: ['U2319809', 'U615280']}],
    ['trust', {name: 'Trust', accountIds: ['U10516305', 'U10525656']}],
    ['total_shah', {name: 'Total Shah', accountIds: ['U10516305', 'U10525656', 'U2319809', 'U615280']}],
    ['total_studio', {name: 'Total Studio', accountIds: []}],
])

const listOfRows = {
    'nav': 'NAV',
    'totalLong': 'Total Long',
    'totalShort': 'Total Short',
    'grossValue': 'GROSS $',
    'longStock': 'Long Stock',
    'shortStock': 'Short Stock',
    'hedgesExclCryptoAndRates': 'Hedges (Excl. Crypto & Rates)',
    'optionsDelta': 'Options (Delta Dollars)',
    'netValue': 'NET $',
    'grossPercent': 'GROSS %',
    'netPercent': 'NET %',
};

const getColumnDefs = (accountIdMapOverride) => {
    let accountColumns = [];
    let commonColumnObject = {
        cellStyle: {textAlign: 'right'},
        cellRenderer: customCellRenderer,
        width: 150,
    };

    for (let [accountId, accountDetails] of accountIdMapOverride ?? accountIdMap) {
        let accountColumnObject = {
            ...commonColumnObject,
            headerName: accountDetails.name,
            headerClass: 'ag-right-aligned-header',
            field: accountId,
        };

        accountColumns.push(accountColumnObject);
    }

    return [
        {
            headerName: ' ',
            field: 'rowName',
            cellStyle: {textAlign: 'left'},
            width: 290,
        },
        ...accountColumns
    ];
}

// Custom cell renderer function
function customCellRenderer(params) {
    const rowId = params.data.rowId;
    const value = params.value;

    // Customize formatting based on the 'rowId' value
    const formattedValue = rowId.toLowerCase().endsWith('percent') ? percentRenderer(value) : numericalRenderer(value);
    return formattedValue;
}

function numericalRenderer(number) {
    if (!number) {
        return number;
    }

    if (Math.floor(Math.abs(number)) === 0) {
        return '-';
    }

    const accountingNotation = number < 0
        ? `(${Math.floor(Math.abs(number)).toLocaleString("en-US")})`
        : `${Math.floor(number).toLocaleString("en-US")}` + ' ';

    return accountingNotation;
}

function percentRenderer(number) {
    if (!number) {
        return number;
    }

    return (number * 100).toFixed(2) + '%' + ' ';
}

function ExposureTable(props) {
    const gridRef = useRef();
    const [exposureAccountMap, setExposureAccountMap] = useState(new Map());
    const [loading, setLoading] = useState(false);
    const [columnDefs, setColumnDefs] = useState(getColumnDefs(props.accountIdMapOverride));
    const [rowData, setRowData] = useState([]);

    const defaultColDef = useMemo(() => ({
        sortable: false,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
        menuTabs: [],
    }), []);

    const getRowDefs = (accountId, accountData) => {
        let newRows = [];
        for (let rowId in listOfRows) {
            let currentRow = {
                rowId: rowId,
                rowName: listOfRows[rowId],
                [accountId]: accountData[rowId],
            }

            newRows.push(currentRow);
        }
        return newRows;
    }

    useEffect(() => {
        const abortController = new AbortController();
        setLoading(true);

        const fetchData = async () => {
            try {
                const promises = [];
                for (let [accountId, accountDetails] of props.accountIdMapOverride ?? accountIdMap) {
                    if (Boolean(props?.intraday)) {
                        promises.push(
                            getIntradayExposureData(accountDetails.accountIds, Boolean(props.betaAdjusted), abortController)
                                .then((res) => {
                                    console.log("Response received for intraday exposure data");
                                    if(props?.setAsOfTime) {
                                        props?.setAsOfTime(res?.data?.asOfTime);
                                    }
                                    return getRowDefs(accountId, res.data);
                                })
                        );
                    } else {
                        promises.push(
                            getExposureData(props.reportDate, accountDetails.accountIds, abortController)
                                .then((res) => {
                                    console.log("Response received for exposure data");
                                    return getRowDefs(accountId, res.data);
                                })
                        );
                    }
                }

                const newRows = await Promise.all(promises);
                const mergedRows = newRows.reduce((acc, rows) => mergeArrayOfObjects(acc, rows), []);
                setRowData(mergedRows);
                setLoading(false);
            } catch (error) {
                console.error('Error faced while getting exposure data from backend', error);
                setLoading(false);
            }
        };

        fetchData();

        return () => abortController.abort();
    }, [props.reportDate]);

    const mergeArrayOfObjects = (first, second) => {
        let merged = [];
        for (let row of second) {
            let currentRow = first.find(r => r.rowId === row.rowId);
            if (!currentRow) {
                merged.push(row);
            } else {
                let {rowId, rowName, ...everythingElse} = row;
                merged.push({
                    ...currentRow,
                    ...everythingElse
                });
            }
        }

        return merged;
    }

    return (
        <div id="exposure-table" style={{marginTop: 10}}>
            {dayjs(props.reportDate).isBefore(dayjs('2024-02-05')) && <Alert
                message="Delta Dollars for Options isn't calculated correctly before 5th Feb, 2024. Please see a later date for accurate calculations"
                type="error"/>}
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="nav-grid ag-theme-alpine" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        animateRows={true}
                        showLoadingOverlay={true}
                        rowSelection='multiple'
                        alwaysShowVerticalScroll={true}
                        debounceVerticalScrollbar={true}
                        suppressColumnVirtualisation={true}
                        domLayout="print"
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default ExposureTable;