import {NextEarningsDateProvider} from "../commonContexts/NextEarningsDateProvider";
import {TickerBar} from "./TickerBar";
import {ChartGrid} from "./ChartGrid";
import {memo} from "react";
import {COMPACT_VIEW_QUERY_LIST} from "../../../api/data/DashboardQueryTypes";

export const DashboardReportCompactView = memo((props) => {
    return (<>

                <NextEarningsDateProvider activeTick={props.tick}>
                    <TickerBar activeTick={props.tick} brand={props.brand} setBrand={props.setBrand}
                               showDataLag={false}
                               showViewControls={false}
                               showPositionsTooltips={true}
                    />
                </NextEarningsDateProvider>
                <ChartGrid queryList={COMPACT_VIEW_QUERY_LIST} tick={props.tick} brand={props.brand}/>
    </>);
})