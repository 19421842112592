import { CurrencyMap }from '../components/vpt/currencyInfo';
import Highcharts from 'highcharts/highstock';

//for these currencies we show native symbols and for rest we show the full currency
const CURR_NATIVE_SET = new Set([
    "USD", // US Dollar
    "GBP", // British Pound
    "JPY", // Japanese Yen
    "INR", // Indian Rupee
    "CHF", // Swiss Franc
    "EUR", // Euro
    "CNY", // Chinese Yuan
])

//TODO: Add a mode option, as this is used for tooltip + axis labels + legend.
export const HighChartsFormatters = {
    "SmallNumber": function (value, decimals=1) {
        return Highcharts.numberFormat(value, decimals, '.', ',');
    },
    "LargeNumber": function (value) {
        if (value >= 1e9) {
            return Highcharts.numberFormat(value / 1e9, 1, '.', ',') + 'B';
        } else if (value >= 1e6) {
            return Highcharts.numberFormat(value / 1e6, 0, '.', ',') + 'M';
        } else {
            return Highcharts.numberFormat(value, 0, '.', ',');
        }
    },
    "SmallFraction": function (value) {
        return Highcharts.numberFormat(value, 1, '.', ',') + 'x';
    },
    "Percent": function (value) {
        return Highcharts.numberFormat(value, 1, '.', ',') + '%';
    },
    "DefaultFormatter": function (value) {
        if (value >= 1e9) {
            return Highcharts.numberFormat(value / 1e9, 1, '.', ',') + 'B';
        } else if (value >= 1e6) {
            return Highcharts.numberFormat(value / 1e6, 0, '.', ',') + 'M';
        } else if (value >= 1e3){
            return Highcharts.numberFormat(value, 0, '.', ',');
        } else {
            return Highcharts.numberFormat(value, 1, '.', ',');
        }
    }
}

export function formatNumber(number,decimals=1) {
    if (number === null || number === undefined) {
        return null;
    }

    const absNumber = Math.abs(number);
    let formattedNumber;
    
    if (absNumber >= 1e12) {
        formattedNumber = (number / 1e12).toFixed(Math.max(2, decimals)) + 't';
    } else if (absNumber >= 1e9) {
        formattedNumber = (number / 1e9).toFixed(Math.max(2, decimals)) + 'b';
    } else if (absNumber >= 1e6) {
        formattedNumber = (number / 1e6).toFixed(decimals) + 'm';
    } else if (absNumber >= 1e3) {
        formattedNumber = (number / 1e3).toFixed(decimals) + 'k';
    } else {
        formattedNumber = number.toFixed(decimals);
    }

    // Remove trailing zeros and decimal point if not needed
    formattedNumber = formattedNumber.replace(/\.00$/, '');

    return formattedNumber;
}

export function formatPercent(number) {
    if(number === null || number === undefined) {
        return '';
    }
    return formatNumber(number) + "%";
}

export function toolTipNumberFormatter() {
    const series = this.series;
    let value = this.y;
    let pointFormatter = this.series.yAxis.options.labelFormatter || 'DefaultFormatter';
    value = HighChartsFormatters[pointFormatter](value);
    return `<span style="color:${series.color}">\u25CF</span> ${series.name}: <b>${value}</b><br/>`;
}


export function formatCurrency(currency) {
    if(!currency) {
        return '';
    }
    if(CURR_NATIVE_SET.has(currency)) {
        return CurrencyMap[currency]?.symbolNative;
    }
    return currency;
}

