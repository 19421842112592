import {monitor} from "../../../event_handler/KeyPressMonitor";
import {useState} from "react";
import {ListSelectionBar} from "./ListSelectionBar";
import {TickerWatchListProvider} from "../commonContexts/TickerWatchListProvider";
import {ListsProvider} from "../commonContexts/ListsProvider";
import {TickerBar} from "./TickerBar";
import {NextEarningsDateProvider} from "../commonContexts/NextEarningsDateProvider";
import {ViewContextProvider} from "../DashboardReport/views/ViewContext";
import {DashboardReportContextProvider} from "../DashboardReport/DashboardReportContextProvider";
import {COMPACT_VIEW_QUERY_LIST} from "../../../api/data/DashboardQueryTypes";
import {ChartGrid} from "./ChartGrid";
import {VPTGridProvider} from "../../vpt/VPTGridProvider";
import {RefreshContextProvider} from "../commonContexts/RefreshContextProvider";

const DEFAULT_BRAND_STATE = {
    name: "Select Brand",
    id: null
};
export const CompactView = () => {
    monitor.enableHotKeys(true);

    const [tick, setTick] = useState({
        name: "Select Ticker",
        id: null
    });

    const [brand, setBrand] = useState(DEFAULT_BRAND_STATE);

    const modifyTick = tick => {
        setTick(tick);
        setBrand(DEFAULT_BRAND_STATE);
    };


    return (
        <>
            <RefreshContextProvider>
                <ViewContextProvider tick={tick} brand={brand}>
                    <ListsProvider activeTick={tick} setActiveTick={modifyTick}>
                        <TickerWatchListProvider activeTick={tick}>
                            <NextEarningsDateProvider activeTick={tick}>
                                <ListSelectionBar activeTick={tick} brand={brand} setBrand={setBrand} showDataLag={true}
                                                  showViewControls={true}
                                                  tooltipMode={false}/>
                                <TickerBar activeTick={tick} brand={brand} setBrand={setBrand} showDataLag={true}
                                           showViewControls={true}/>
                            </NextEarningsDateProvider>
                            <DashboardReportContextProvider
                                brand={brand}
                                tick={tick}
                                key={`${tick?.id}-${brand?.id}`}
                                customQueryList={COMPACT_VIEW_QUERY_LIST}
                            >
                                <VPTGridProvider activeTick={tick}>
                                    <ChartGrid queryList={COMPACT_VIEW_QUERY_LIST} tick={tick} brand={brand}/>
                                </VPTGridProvider>
                            </DashboardReportContextProvider>
                        </TickerWatchListProvider>
                    </ListsProvider>
                </ViewContextProvider>
            </RefreshContextProvider>
        </>
    );

}