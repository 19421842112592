import {useContext, useEffect, useRef, useState} from "react";
import {getWtdStacks} from "../../../../api/data/DataProvider";
import {RefreshContext} from "../../commonContexts/RefreshContextProvider";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {Modal} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


export const SECONDMEASURE = 'SECONDMEASURE';
export const ORION = 'ORION';
export const VELA_GAMMA = 'VELA_GAMMA';
export const VELA_VELORUM = 'VELA_VELORUM';
export const ORION_DEBIT = 'ORION_DEBIT';
export const OICC = 'OICC';

const ALL_CC_SERIES = [ORION_DEBIT, ORION, VELA_VELORUM, VELA_GAMMA, SECONDMEASURE];


const fixSizeProps = (options, chartHeight, chartWidth) => ({
    ...options,
    chart: {
        ...options?.chart,
        zooming: {
            mouseWheel: {
                enabled: false
            }
        },
        plotBackgroundColor: '#E5ECF6',
        panning: {
            enabled: true,
            type: 'x'
        },
        panKey: 'shift',
        height: chartHeight,
        width: chartWidth,
    }
})

const getInitialState = () => ({
    data: null,
    loading: false,
    isError: false,
})

const ONE_YEAR = '1';
const TWO_YEAR = '2';



export const WTDStacksComponent = ({tick, brand, wtdStackModalOpen, setWtdStackModalOpen}) => {

    const {refresh} = useContext(RefreshContext);

    const [yearFactor, setYearFactor] = useState(ONE_YEAR);

    const [usedParams, setUsedParams] = useState(null);

    const [dataState, setDataState] = useState({
        [SECONDMEASURE]: getInitialState(),
        [ORION]: getInitialState(),
        [VELA_GAMMA]: getInitialState(),
        [VELA_VELORUM]: getInitialState(),
        [ORION_DEBIT]: getInitialState(),
        [OICC]: getInitialState(),
    });

    const abortControllerRef = useRef(null);

    const [chartHeight, setChartHeight] = useState(400);
    const [chartWidth, setChartWidth] = useState(900);

    useEffect(() => {
        /*//Todo: Refresh won't work till the modal is open?
        if ((!wtdStackModalOpen || tick.id == null)) return;*/
        // data is same as before, ignore:
        if (usedParams != null && usedParams.tick === tick.id && usedParams.brand === brand.id
            && usedParams.yearFactor === yearFactor) return;

        //do the initial data fetch only when the modal is opened:
        if (abortControllerRef.current) {
            abortControllerRef.current?.abort();
        }
        abortControllerRef.current = new AbortController();
        setUsedParams({
            tick: tick.id,
            brand: brand.id,
            yearFactor: yearFactor,
        })
        ALL_CC_SERIES.forEach((ccSeries) => {
            setDataState(prevState => ({
                ...prevState,
                [ccSeries]: {
                    data: null,
                    loading: true,
                    isError: false,
                }
            }));

            getWtdStacks(tick.id, brand.id, ccSeries, yearFactor, abortControllerRef.current)
                .then((data) => {
                    setDataState(prev => ({
                        ...prev,
                        [ccSeries]: {
                            data: data.data,
                            loading: false,
                            isError: true
                        }
                    }));
                })
                .catch((err) => {
                    console.error(err);
                    setDataState(prev => ({
                        ...prev,
                        [ccSeries]: {
                            data: null,
                            loading: false,
                            isError: true
                        }
                    }))
                })

        });

    }, [/*wtdStackModalOpen,*/ tick, brand, yearFactor, refresh]);

    const getChartForCCSeries = (ccSeries, chartHeight, chartWidth) => (
        <div key={`ChartGrid-Chart-wtdStack-${ccSeries}`} className="grid-item-wtd-stack">
            <HighchartsReact
                highcharts={Highcharts}
                options={fixSizeProps(dataState[ccSeries].data, chartHeight, chartWidth)}
            />
        </div>
    );


    const close = () => setWtdStackModalOpen(false);

    return (
        <Modal width={2500} open={wtdStackModalOpen} onOk={close} onCancel={close}>
            <Stack spacing={2} direction={"row"}>
                <Button variant={yearFactor === ONE_YEAR ? 'contained' : 'outlined'}
                        onClick={() => setYearFactor(ONE_YEAR)}>1 Year</Button>
                <Button variant={yearFactor === TWO_YEAR ? 'contained' : 'outlined'}
                        onClick={() => setYearFactor(TWO_YEAR)}>2 Year</Button>
            </Stack>
            <div className="grid-container-wtd-stack">
                {ALL_CC_SERIES.map((ccSeries) => getChartForCCSeries(ccSeries, chartHeight, chartWidth))}
            </div>
        </Modal>
    );


}
