import {createContext, useContext, useEffect, useState} from "react";
import {SeriesTypes} from "../../../api/data/SeriesTypes";
import {computationService} from "../../../api/Clients";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";

const fetchFinMatrixData = async (tickerId, seriesType) => {
    const response = await computationService.get(`/v1/multiples/ticker/finratios/vpt-matrices?tickerId=${tickerId}&metric=${seriesType}`);
    return response.data;
}

const fetchLineItemsData = async () => {
    const response = await computationService.get(`/v1/multiples/lineitems`);
    return response.data;
}
const transformLineItemData = (lineItemData) => {
    if (!lineItemData) {
        return null;
    }
    return lineItemData['lineitems'];
}

export const FinancialMatricesContext = createContext(null);

export const FinancialMatricesDataProvider = ({activeTicker, children}) => {

    const {refresh} = useContext(RefreshContext);

    const [fmDS1, setFmDS1] = useState({
        loading: true,
        error: null,
        data: []
    });
    const [fmDS2, setFmDS2] = useState({
        loading: true,
        error: null,
        data: []
    });
    const [fmDS3, setFmDS3] = useState({
        loading: true,
        error: null,
        data: []
    });
    const [fmDS4, setFmDS4] = useState({
        loading: true,
        error: null,
        data: []
    });

    const [finMatrixSeriesType1, setFinMatrixSeriesType1] = useState(SeriesTypes.SALES);
    const [finMatrixSeriesType2, setFinMatrixSeriesType2] = useState(SeriesTypes.GROSSPROFIT);
    const [finMatrixSeriesType3, setFinMatrixSeriesType3] = useState(SeriesTypes.EBITDA);
    const [finMatrixSeriesType4, setFinMatrixSeriesType4] = useState(SeriesTypes.EPSDILUTED);

    const fetchAndSetData = async (setFmDS, seriesType) => {
        if (activeTicker) {

            try {
                setFmDS(prevState => ({
                    ...prevState,
                    loading: true,
                    data: []
                }));
                const data = await fetchFinMatrixData(activeTicker.id, seriesType);
                setFmDS(prevState => ({
                    ...prevState,
                    loading: false,
                    data: data
                }));
            } catch (error) {
                setFmDS(prevState => ({
                    ...prevState,
                    loading: false,
                    error: error
                }));
            }
        }
    };

    useEffect(() => {
        fetchAndSetData(setFmDS1, finMatrixSeriesType1);
    }, [activeTicker, finMatrixSeriesType1, refresh]);

    useEffect(() => {
        fetchAndSetData(setFmDS2, finMatrixSeriesType2);
    }, [activeTicker, finMatrixSeriesType2, refresh]);

    useEffect(() => {
        fetchAndSetData(setFmDS3, finMatrixSeriesType3);
    }, [activeTicker, finMatrixSeriesType3, refresh]);

    useEffect(() => {
        fetchAndSetData(setFmDS4, finMatrixSeriesType4);
    }, [activeTicker, finMatrixSeriesType4, refresh]);

    const [lineItemsData, setLineItemsData] = useState({
        loading: true,
        error: null,
        data: null
    });

    useEffect(() => {
        fetchLineItemsData().then((data) => {
            setLineItemsData({
                loading: false,
                error: null,
                data: data
            });
        }).catch((error) => {
            setLineItemsData({
                loading: false,
                error: error,
                data: null
            });
        });
    }, [refresh])

    return (
        <FinancialMatricesContext.Provider value={{
            fmDS1, fmDS2, fmDS3, fmDS4, lineItems : transformLineItemData(lineItemsData.data), finMatrixSeriesType1, finMatrixSeriesType2, finMatrixSeriesType3, finMatrixSeriesType4, setFinMatrixSeriesType1, setFinMatrixSeriesType2, setFinMatrixSeriesType3, setFinMatrixSeriesType4
        }}>
            {children}
        </FinancialMatricesContext.Provider>
    )
}