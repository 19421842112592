import {AgGridReact} from "@ag-grid-community/react";
import {useEffect, useMemo, useState} from "react";
import LoadingOverlay from "react-loading-overlay";
import {getAllPositions, getIntradayPositions} from "../../../api/data/PortfolioDataProvider";
import {columnDefs, intradayColumnDefs} from "./configuration/PositionsOverviewConfiguration";
import {Col, Radio, Row, Switch} from "antd";

function PositionTable(props) {
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [livePrices, setLivePrices] = useState(false);
    const [frequency, setFrequency] = useState('60');

    const defaultColDef = useMemo(() => ({
        sortable: false,
        marryChildren: true,
        resizable: true,
        sizeColumnsToFit: true,
        skipHeaderOnAutoSize: true,
        suppressMovable: true,
        suppressDragLeaveHidesColumns: true,
    }), []);

    const onPriceToggleChange = (checked) => {
        setLivePrices(checked);
    };

    const onFrequencyChange = (e) => {
        setFrequency(e.target.value);
    };

    const extractDataFromResponse = (res) => {
        let data = res.data;
        data.sort((a, b) => b.currentMarketValue - a.currentMarketValue);
        return data.map(o => {
            return {...o, combinedTicker: o.studioTicker ?? o.bbTicker ?? o.symbol}
        });
    }

    const loadData = () => {
        const abortController = new AbortController();
        if (Boolean(props?.intraday)) {
            getIntradayPositions(abortController)
                .then((res) => {
                    let data = extractDataFromResponse(res);
                    setRowData(data);
                })
                .catch((err) => {
                    console.error("Failed to get all positions!", err);
                })
        } else {
            getAllPositions(props.reportDate, livePrices, abortController)
                .then((res) => {
                    let data = extractDataFromResponse(res);
                    setRowData(data);
                })
                .catch((err) => {
                    console.error("Failed to get all positions!", err);
                })
        }

        return () => abortController.abort();
    }

    useEffect(() => {
        setLoading(true);
        loadData();
        setLoading(false);
    }, [props.reportDate]);

    useEffect(() => {
        let intervalId;

        if (livePrices) {
            loadData();
            intervalId = setInterval(() => {
                loadData();
            }, frequency * 1000);
        } else {
            loadData();
        }

        return () => clearInterval(intervalId);
    }, [livePrices, frequency]);

    return (
        <div id='position-table' style={{marginTop: 10}}>
            <Row gutter={16} justify="center" style={{marginBottom: 5}}>
                <Col span={4} offset={8}>
                    <Switch onChange={onPriceToggleChange} checkedChildren='Live Prices'
                            unCheckedChildren='EOD Prices'/>
                </Col>
                <Col span={8}>
                    {livePrices &&
                        <Radio.Group value={frequency} onChange={onFrequencyChange} size="small" buttonStyle="solid">
                            <Radio.Button value="10">10 s</Radio.Button>
                            <Radio.Button value="60">1 min</Radio.Button>
                            <Radio.Button value="600">10 min</Radio.Button>
                        </Radio.Group>}
                </Col>
            </Row>
            <LoadingOverlay active={loading} text={"Loading..."}>
                <div className="nav-grid ag-theme-alpine" style={{height: '70vh', width: '800px', margin: '0px auto'}}>
                    <AgGridReact
                        grandTotalRow={'top'}
                        columnDefs={Boolean(props?.intraday) ? intradayColumnDefs : columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        pagination={false}
                    />
                </div>
            </LoadingOverlay>
        </div>
    )
}

export default PositionTable;