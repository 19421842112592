import { Table } from "antd";
import { useEffect, useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { getFactorWeights } from "../../api/data/PortfolioDataProvider";
import {useFactorWeightsProviderHook} from "../oracle/commonHooks/FactorWeightsProviderHook";

const limitDecimalsRenderer = (num) => {
    return <>{Math.round((num + Number.EPSILON) * 10000) / 10}</>
}

const orderOfColumns = [
    { dataIndex: 'growth', title: 'Growth', render: limitDecimalsRenderer },
    { dataIndex: 'volatility', title: 'Volatility', render: limitDecimalsRenderer },
    { dataIndex: 'value', title: 'Value', render: limitDecimalsRenderer },
    { dataIndex: 'shortInterest', title: 'SI', render: limitDecimalsRenderer },
    { dataIndex: 'momentum', title: 'Momentum', render: limitDecimalsRenderer },
    { dataIndex: 'size', title: 'Size', render: limitDecimalsRenderer },
    { dataIndex: 'leverage', title: 'Leverage', render: limitDecimalsRenderer },
    { dataIndex: 'earnYield', title: 'Earn Yield', render: limitDecimalsRenderer },
    { dataIndex: 'divYield', title: 'Div Yield', render: limitDecimalsRenderer },
    { dataIndex: 'profitability', title: 'Profitability', render: limitDecimalsRenderer },
    { dataIndex: 'volume', title: 'Volume', render: limitDecimalsRenderer },
];

export function generateFactorWeightsTableFromData(loading, factorWeights, isCentered = true) {
    const TABLE = <Table
        dataSource={[factorWeights]}
        columns={orderOfColumns}
        size="small"
        pagination={{hideOnSinglePage: true}}
    />;
    return (
        <div>
            <div id="factor-weight-table" style={{height: '0px'}}></div>
            <LoadingOverlay
                active={loading}
                spinner={loading}
                text={"Loading..."}
            >
                {isCentered ? (
                    <center>
                        <div style={{width: '60%', marginTop: 0, paddingLeft: 0}}>
                            {TABLE}
                        </div>
                    </center>
                ) : (
                    <div style={{width: '100%', marginTop: 0, paddingLeft: 0}}>
                        {TABLE}
                    </div>
                )}
            </LoadingOverlay>

        </div>
    )
}
/*scroll={{x: false, y: false}}
                            */
function FactorWeightTable(props) {
    const {factorWeights, loading} = useFactorWeightsProviderHook(props.activeTickerId);
    return generateFactorWeightsTableFromData(loading, factorWeights);
}

export default FactorWeightTable;