import {createContext, useState} from "react";

export const RefreshContext = createContext();

export const RefreshContextProvider = ({children}) => {

    const [refresh, setRefresh] = useState(false);

    const toggleRefresh = () => setRefresh(prev => !prev);

    return (
        <RefreshContext.Provider value={{ refresh, toggleRefresh }}>
            {children}
        </RefreshContext.Provider>
    )
}