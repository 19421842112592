import {CAlert} from "@coreui/react";
import {Switch} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useEffect, useState} from "react";
import LoadingOverlay from 'react-loading-overlay';
import {getSpreadData} from "../../../api/data/PortfolioDataProvider";

/**
 * Custom Axis extension to allow emulation of negative values on a logarithmic
 * Y axis. Note that the scale is not mathematically correct, as a true
 * logarithmic axis never reaches or crosses zero.
 */
(function (H) {
    /* eslint-disable no-underscore-dangle */
    const logAdditions =
        H._modules['Core/Axis/LogarithmicAxis.js'].Additions.prototype;

    H.addEvent(H.Axis, 'afterInit', function () {
        const logarithmic = this.logarithmic;

        if (logarithmic && this?.options?.custom?.allowNegativeLog) {

            // Avoid errors on negative numbers on a log axis
            this.positiveValuesOnly = false;

            // Override the converter functions
            logarithmic.log2lin = num => {
                const isNegative = num < 0;

                let adjustedNum = Math.abs(num);

                if (adjustedNum < 10) {
                    adjustedNum += (10 - adjustedNum) / 10;
                }

                const result = Math.log(adjustedNum) / Math.LN10;
                return isNegative ? -result : result;
            };

            logarithmic.lin2log = num => {
                const isNegative = num < 0;

                let result = Math.pow(10, Math.abs(num));
                if (result < 10) {
                    result = (10 * (result - 1)) / (10 - 1);
                }
                return isNegative ? -result : result;
            };
        }
    });

    // Add support for negative axis values to the tick positioning function.
    H.wrap(logAdditions, 'getLogTickPositions', function (
        proceed,
        interval,
        min,
        max,
        minor
    ) {
        if (
            !this.axis?.options?.custom?.allowNegativeLog ||
            interval >= 0.5 ||
            interval < 0.08
        ) {
            return proceed.call(this, interval, min, max, minor);
        }

        const log = this,
            roundedMin = Math.floor(min),
            positions = [];
        let intermediate,
            i,
            j,
            len,
            pos,
            lastPos,
            break2;

        if (interval > 0.3) {
            intermediate = [1, 2, 4];
        } else if (interval > 0.15) {
            intermediate = [1, 2, 4, 6, 8];
        } else {
            intermediate = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        }

        for (i = roundedMin; i < max + 1 && !break2; i++) {
            len = intermediate.length;
            if (i <= 0) {
                for (j = len - 1; j >= 0 && !break2; j--) {
                    pos = -log.log2lin(
                        (log.lin2log(-i) || 1) * intermediate[j]
                    );

                    if (pos > min &&
                        (!minor || lastPos <= max) &&
                        typeof lastPos !== 'undefined') {
                        positions.push(lastPos);
                    }
                    if (lastPos > max) {
                        break2 = true;
                    }
                    lastPos = pos;
                }

                if (lastPos < min || lastPos > max) {
                    lastPos = undefined;
                }
            }

            if (i === 0 && min <= 0 && max >= 0) {
                positions.push(0);
            }

            if (i >= 0) {
                for (j = 0; j < len && !break2; j++) {
                    pos = log.log2lin((log.lin2log(i) || 1) * intermediate[j]);

                    if (pos > min &&
                        (!minor || lastPos <= max) &&
                        typeof lastPos !== 'undefined') {
                        positions.push(lastPos);
                    }
                    if (lastPos > max) {
                        break2 = true;
                    }
                    lastPos = pos;
                }
            }
        }

        return positions;
    });

}(Highcharts));

function getColor(seriesName) {
    if (seriesName.startsWith('equal')) {
        return '#ff00e4';
    } else if (seriesName.startsWith('contribution')) {
        return '#636efa';
    } else if (seriesName.startsWith('long')) {
        return '#00ba37';
    } else if (seriesName.startsWith('short')) {
        return '#ef553b';
    } else {
        return '#00cc96';
    }
}

function SpreadChart(props) {
    const [chartData, setChartData] = useState(null);
    const [grossChartData, setGrossChartData] = useState(null);
    const [loading, setLoading] = useState(chartData == null);
    const [statusMsg, setStatusMsg] = useState('Loading your content...');
    const [isError, setIsError] = useState(false);
    const [logScale, setLogScale] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();

        setIsError(false)
        setLoading(true)
        getSpreadData(abortController)
            .then((res) => {
                setChartData((prev) => {
                    return {
                        series: [...calculateSeriesData(res.data)],
                        title: {
                            text: 'Equity Spread'
                        },
                        tooltip: {
                            shared: true,
                            split: true,
                            inactiveOtherSeries: false,
                            xDateFormat: "%A, %e %B, %Y",
                            pointFormatter: function () {
                                return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>' +
                                    Highcharts.numberFormat(this.y * 100, 2) + '%</b><br/>';
                            },
                        },
                        chart: {
                            zoomType: "x",
                            zooming: {
                                mouseWheel: {
                                    enabled: false
                                }
                            },
                            height: 600,
                            plotBackgroundColor: '#E5ECF6',
                            panning: {
                                enabled: true,
                                type: 'x'
                            },
                            panKey: 'shift'
                        },
                        legend: {
                            enabled: true,
                            align: "right",
                            verticalAlign: "middle",
                            layout: "vertical",
                            width: 200,
                            itemWidth: 200,
                            itemStyle: {
                                color: "#000000",
                                fontSize: '12px',
                                textOverflow: 'ellipsis'
                            },
                            itemHiddenStyle: {
                                textDecoration: 'none'  // Disable strikethrough effect
                            }
                        },
                        navigation: {
                            buttonOptions: {
                                align: "right",
                                verticalAlign: "top",
                                y: 10
                            }
                        },
                        plotOptions: {
                            marker: {
                                enabled: false
                            }
                        },
                        xAxis: {
                            type: "datetime",
                            labels: {
                                enabled: true
                            },
                            overscroll: '60px',
                            dateTimeLabelFormats: {
                                day: "%Y-%m-%d"
                            },
                            gridLineWidth: 1,
                            gridLineColor: "#fff"
                        },
                        yAxis: [
                            {
                                title: {
                                    text: "common_yaxis"
                                },
                                visible: false,
                                opposite: false,
                                gridLineWidth: 1,
                                gridLineColor: "#fff",
                                labels: {
                                    format: "{value:.0f}"
                                }
                            }
                        ]
                    }
                });
                setGrossChartData((prev) => {
                    return {
                        series: [...getGrossChartSeriesData(res.data)],
                        title: {
                            text: 'Gross Value'
                        },
                        tooltip: {
                            shared: true,
                            split: true,
                            inactiveOtherSeries: false,
                            xDateFormat: "%A, %e %B, %Y",
                            pointFormatter: function () {
                                return '<span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': <b>$' +
                                    Highcharts.numberFormat(this.y, 0, '.', ',') + '</b><br/>';
                            },
                        },
                        chart: {
                            zoomType: "x",
                            zooming: {
                                mouseWheel: {
                                    enabled: false
                                }
                            },
                            height: 600,
                            plotBackgroundColor: '#E5ECF6',
                            panning: {
                                enabled: true,
                                type: 'x'
                            },
                            panKey: 'shift'
                        },
                        legend: {
                            enabled: true,
                            align: "right",
                            verticalAlign: "middle",
                            layout: "vertical",
                            width: 200,
                            itemWidth: 200,
                            itemStyle: {
                                color: "#000000",
                                fontSize: '12px',
                                textOverflow: 'ellipsis'
                            },
                            itemHiddenStyle: {
                                textDecoration: 'none'  // Disable strikethrough effect
                            }
                        },
                        navigation: {
                            buttonOptions: {
                                align: "right",
                                verticalAlign: "top",
                                y: 10
                            }
                        },
                        plotOptions: {
                            marker: {
                                enabled: false
                            }
                        },
                        xAxis: {
                            type: "datetime",
                            labels: {
                                enabled: true
                            },
                            overscroll: '60px',
                            dateTimeLabelFormats: {
                                day: "%Y-%m-%d"
                            },
                            gridLineWidth: 1,
                            gridLineColor: "#fff"
                        },
                        yAxis: [
                            {
                                title: {
                                    text: "common_yaxis"
                                },
                                visible: false,
                                opposite: false,
                                gridLineWidth: 1,
                                gridLineColor: "#fff",
                                labels: {
                                    format: "{value:.0f}"
                                }
                            }
                        ]
                    }
                });
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err.code === "ERR_CANCELED") {
                    // do nothing for now
                } else {
                    setStatusMsg("Failed to load data for the chart. Looks like this data is not available.")
                    setIsError(true);
                }
            })


        return () => abortController.abort();
    }, []);

    const spreadSeriesOrder = [
        'contributionWeightedSpread',
        'equalWeightedSpread',
        'longStockPerformance',
        'longStockIdioPerformance',
        'shortStockPerformance',
        'shortStockIdioPerformance',
    ];

    const grossSeriesOrder = [
        'grossValue',
    ];

    function calculateSeriesData(rawData) {
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && spreadSeriesOrder.includes(series[0].source)) {
                series.sort((a, b) => {
                    return a.dataDateMillis - b.dataDateMillis;
                });

                let currentValue = 1.0;
                let newSeriesData = [];
                for (let point of series) {
                    if (newSeriesData.length !== 0) {
                        currentValue = currentValue * (1.0 + point?.value);
                    }
                    let newPoint = [point?.dataDateMillis, currentValue - 1.0];
                    newSeriesData.push(newPoint);
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: true,
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: series[0].source.includes('Idio') ? 'dash' : 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => spreadSeriesOrder.indexOf(a.name) - spreadSeriesOrder.indexOf(b.name));
        return seriesArr;
    }

    function getGrossChartSeriesData(rawData) {
        let seriesArr = [];

        for (const series of Object.values(rawData)) {
            if (series.length !== 0 && grossSeriesOrder.includes(series[0].source)) {
                series.sort((a, b) => {
                    return a.dataDateMillis - b.dataDateMillis;
                });

                let newSeriesData = [];
                for (let point of series) {
                    let newPoint = [point?.dataDateMillis, point?.value];
                    newSeriesData.push(newPoint);
                }

                let newSeries = {
                    data: newSeriesData,
                    name: series[0].source,
                    visible: true,
                    showInLegend: true,
                    color: getColor(series[0].source),
                    lineWidth: 2,
                    dashStyle: 'solid',
                    yAxis: 0
                };

                seriesArr.push(newSeries);
            }
        }

        seriesArr.sort((a, b) => grossSeriesOrder.indexOf(a.name) - grossSeriesOrder.indexOf(b.name));
        return seriesArr;
    }

    const onLogScaleChange = (checked) => {
        setLogScale(checked);
    };

    console.log("gross chart data = ", grossChartData);

    return (
        <>
            <a id='spread-chart' href={""}> </a>
            <LoadingOverlay
                active={loading && !isError}
                spinner={!isError}
                text={"Loading..."}
            >
                {isError && <div>
                    <CAlert color="danger">
                        Failed to load chart.
                        Data Not Configured
                    </CAlert>
                </div>}
                {chartData == null && !isError && <p style={{height: "400px", width: "90%"}}> ...</p>}
                {chartData != null && !isError && chartData.series.length === 0 && <div>
                    <CAlert color="warning">
                        Data Not Available
                    </CAlert>
                </div>}
                {chartData != null && !isError && chartData.series.length !== 0 &&
                    <center>
                        <Switch onChange={onLogScaleChange} checkedChildren='Log Scale'
                                unCheckedChildren='Linear Scale'/>
                        <div style={{marginTop: 10}}>
                            {logScale && <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={{
                                    ...chartData,
                                    yAxis: {
                                        type: 'logarithmic',
                                        custom: {
                                            // Turning this to true should handle negative numbers, but doesn't seem to work correctly
                                            // Reference: https://stackoverflow.com/a/63518266
                                            allowNegativeLog: false
                                        },
                                    }
                                }}
                            />}
                            {!logScale && <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={chartData}
                            />}
                        </div>
                    </center>}
                {grossChartData != null && !isError && grossChartData.series.length !== 0 &&
                    <center>
                        <div style={{marginTop: 10}}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                constructorType={'stockChart'}
                                options={grossChartData}
                            />
                        </div>
                    </center>}
            </LoadingOverlay>

        </>
    )
}

export default SpreadChart;