export const DashboardQueryTypes = {
    // Charts in Mini-view:
    WEEKLY_YOY: 'WEEKLY_YOY',
    QTR_YOY_LAG0: 'QTR_YOY_LAG0',
    WEEKLY_YOY2: 'WEEKLY_YOY2',
    QTR_YOY2_LAG0: 'QTR_YOY2_LAG0',
    WEEKLY_YOY3: 'WEEKLY_YOY3',
    WEEKLY_YOY4: 'WEEKLY_YOY4',
    WEEKLY_YOY5: 'WEEKLY_YOY5',
    WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX: 'WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX',
    WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX: 'WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX',
    WEEKLY_PROFIT_YOY_LAG0: 'WEEKLY_PROFIT_YOY_LAG0',
    WEEKLY_PRICING_YOY_LAG0: 'WEEKLY_PRICING_YOY_LAG0',
    WEEKLY_TRANSACTIONS_YOY_LAG0: 'WEEKLY_TRANSACTIONS_YOY_LAG0',
    WEEKLY_PROFIT_YOY2_LAG0: 'WEEKLY_PROFIT_YOY2_LAG0',
    WEEKLY_PRICING_YOY2_LAG0: 'WEEKLY_PRICING_YOY2_LAG0',
    WEEKLY_TRANSACTIONS_YOY2_LAG0: 'WEEKLY_TRANSACTIONS_YOY2_LAG0',
    WEEKLY_LEVELS: 'WEEKLY_LEVELS',
    DAILY_YOY: 'DAILY_YOY',
    DAILY_YOY_ALIGNED: 'DAILY_YOY_ALIGNED',
    PROFIT_YOY_LAG0: 'PROFIT_YOY_LAG0',
    PROFIT_YOY_LAG1: 'PROFIT_YOY_LAG1',
    PROFIT_YOY_LAG2: 'PROFIT_YOY_LAG2',
    PRICING_YOY_LAG0: 'PRICING_YOY_LAG0',
    PRICING_YOY_LAG1: 'PRICING_YOY_LAG1',
    PRICING_YOY_LAG2: 'PRICING_YOY_LAG2',
    QTR_TRANSACTIONS_YOY_LAG0: 'QTR_TRANSACTIONS_YOY_LAG0',
    PROFIT_YOY2_LAG0: 'PROFIT_YOY2_LAG0',
    PROFIT_YOY2_LAG1: 'PROFIT_YOY2_LAG1',
    PROFIT_YOY2_LAG2: 'PROFIT_YOY2_LAG2',
    PRICING_YOY2_LAG0: 'PRICING_YOY2_LAG0',
    PRICING_YOY2_LAG1: 'PRICING_YOY2_LAG1',
    PRICING_YOY2_LAG2: 'PRICING_YOY2_LAG2',
    QTR_TRANSACTIONS_YOY2_LAG0: 'QTR_TRANSACTIONS_YOY2_LAG0',
    QTR_YOY_LAG1: 'QTR_YOY_LAG1',
    QTR_YOY_LAG2: 'QTR_YOY_LAG2',
    QTR_YOY2_LAG1: 'QTR_YOY2_LAG1',
    QTR_YOY2_LAG2: 'QTR_YOY2_LAG2',
    QTR_YOY3_LAG0: 'QTR_YOY3_LAG0',
    QTR_YOY3_LAG1: 'QTR_YOY3_LAG1',
    QTR_YOY3_LAG2: 'QTR_YOY3_LAG2',
    QTR_LEVELS_LAG0: 'QTR_LEVELS_LAG0',
    QTR_LEVELS_LAG1: 'QTR_LEVELS_LAG1',
    QTR_LEVELS_LAG2: 'QTR_LEVELS_LAG2',
    WEEKLY_DYN_ALT_GT_STACK: 'WEEKLY_DYN_ALT_GT_STACK',
    WEEKLY_STACK_GT_WW: 'WEEKLY_STACK_GT_WW',
    WEEKLY_STACK_GT_US: 'WEEKLY_STACK_GT_US',
    WEEKLY_DYN_SW_STACK: 'WEEKLY_DYN_SW_STACK',
    WEEKLY_STACK_SW_WW: 'WEEKLY_STACK_SW_WW',
    DAILY_STACK_SW_WW: 'DAILY_STACK_SW_WW',
    WEEKLY_STACK_SW_US: 'WEEKLY_STACK_SW_US',
    DAILY_STACK_SW_US: 'DAILY_STACK_SW_US',
    WEEKLY_STACK_ER_ORION_SALES: 'WEEKLY_STACK_ER_ORION_SALES',
    DAILY_STACK_ER_ORION_SALES: 'DAILY_STACK_ER_ORION_SALES',
    WEEKLY_STACK_ER_ORION_TRANSACTIONS: 'WEEKLY_STACK_ER_ORION_TRANSACTIONS',
    WEEKLY_STACK_ER_VELA_VELORUM_SALES: 'WEEKLY_STACK_ER_VELA_VELORUM_SALES',
    DAILY_STACK_ER_VELA_VELORUM_SALES: 'DAILY_STACK_ER_VELA_VELORUM_SALES',
    WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS: 'WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS',
    WEEKLY_STACK_ER_VELA_GAMMA_SALES: 'WEEKLY_STACK_ER_VELA_GAMMA_SALES',
    DAILY_STACK_ER_VELA_GAMMA_SALES: 'DAILY_STACK_ER_VELA_GAMMA_SALES',
    WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS: 'WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS',
    WEEKLY_STACK_SM_SALES: 'WEEKLY_STACK_SM_SALES',
    DAILY_STACK_SM_SALES: 'DAILY_STACK_SM_SALES',
    DAILY_STACK_CC_BASELINE: 'DAILY_STACK_CC_BASELINE',
    WEEKLY_STACK_TOTAL_BASELINE: 'WEEKLY_STACK_TOTAL_BASELINE',
    WEEKLY_STACK_APPT_APP_DL_US: 'WEEKLY_STACK_APPT_APP_DL_US',
    WEEKLY_STACK_APPT_APP_DAU_US: 'WEEKLY_STACK_APPT_APP_DAU_US',
    WEEKLY_STACK_APPT_APP_MAU_US: 'WEEKLY_STACK_APPT_APP_MAU_US',
    WEEKLY_STACK_APPT_APP_DL_WW: 'WEEKLY_STACK_APPT_APP_DL_WW',
    WEEKLY_STACK_APPT_APP_DAU_WW: 'WEEKLY_STACK_APPT_APP_DAU_WW',
    WEEKLY_STACK_APPT_APP_MAU_WW: 'WEEKLY_STACK_APPT_APP_MAU_WW',
    WEEKLY_STACK_ST_APP_DL_US: 'WEEKLY_STACK_ST_APP_DL_US',
    WEEKLY_STACK_ST_APP_DAU_US: 'WEEKLY_STACK_ST_APP_DAU_US',
    MONTHLY_STACK_ST_APP_MAU_US: 'MONTHLY_STACK_ST_APP_MAU_US',
    WEEKLY_STACK_ST_APP_DL_WW: 'WEEKLY_STACK_ST_APP_DL_WW',
    WEEKLY_STACK_ST_APP_DAU_WW: 'WEEKLY_STACK_ST_APP_DAU_WW',
    MONTHLY_STACK_ST_APP_MAU_WW: 'MONTHLY_STACK_ST_APP_MAU_WW',
    IDIO: 'IDIO',
    CORRELATION_REPORT: 'CORRELATION_REPORT',
    DAILY_STACK_SW_WW_ALIGNED: 'DAILY_STACK_SW_WW_ALIGNED',
    DAILY_STACK_SW_US_ALIGNED: 'DAILY_STACK_SW_US_ALIGNED',
    DAILY_STACK_ER_ORION_SALES_ALIGNED: 'DAILY_STACK_ER_ORION_SALES_ALIGNED',
    DAILY_STACK_ER_VELA_VELORUM_SALES_ALIGNED: 'DAILY_STACK_ER_VELA_VELORUM_SALES_ALIGNED',
    DAILY_STACK_ER_VELA_GAMMA_SALES_ALIGNED: 'DAILY_STACK_ER_VELA_GAMMA_SALES_ALIGNED',
    DAILY_STACK_SM_SALES_ALIGNED: 'DAILY_STACK_SM_SALES_ALIGNED',
    DAILY_STACK_CC_BASELINE_ALIGNED: 'DAILY_STACK_CC_BASELINE_ALIGNED',
    WEEKLY_STACK_GT_TOP_REGIONS_GRID: 'WEEKLY_STACK_GT_TOP_REGIONS_GRID',
    FIN_RATIOS: 'FIN_RATIOS'
}

export const MINI_VIEW_REQUIRED_QUERIES = [
    DashboardQueryTypes.WEEKLY_YOY,
    DashboardQueryTypes.QTR_YOY_LAG0,
    DashboardQueryTypes.WEEKLY_YOY2,
    DashboardQueryTypes.QTR_YOY2_LAG0,
    DashboardQueryTypes.IDIO
]


// Quarter Charts need to do fresh API calls when axis combination is toggled. Keep the list of axis toggle separate here:
 export const IsCCAxisCombinedDependentCharts = [
     DashboardQueryTypes.QTR_YOY_LAG0,
     DashboardQueryTypes.QTR_YOY2_LAG0,
     DashboardQueryTypes.PROFIT_YOY_LAG0,
     DashboardQueryTypes.PROFIT_YOY_LAG1,
     DashboardQueryTypes.PROFIT_YOY_LAG2,
     DashboardQueryTypes.PRICING_YOY_LAG0,
     DashboardQueryTypes.PRICING_YOY_LAG1,
     DashboardQueryTypes.PRICING_YOY_LAG2,
     DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0,
     DashboardQueryTypes.PROFIT_YOY2_LAG0,
     DashboardQueryTypes.PROFIT_YOY2_LAG1,
     DashboardQueryTypes.PROFIT_YOY2_LAG2,
     DashboardQueryTypes.PRICING_YOY2_LAG0,
     DashboardQueryTypes.PRICING_YOY2_LAG1,
     DashboardQueryTypes.PRICING_YOY2_LAG2,
     DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0,
     DashboardQueryTypes.QTR_YOY_LAG1,
     DashboardQueryTypes.QTR_YOY_LAG2,
     DashboardQueryTypes.QTR_YOY2_LAG0,
     DashboardQueryTypes.QTR_YOY2_LAG1,
     DashboardQueryTypes.QTR_YOY2_LAG2,
     DashboardQueryTypes.QTR_YOY3_LAG0,
     DashboardQueryTypes.QTR_YOY3_LAG1,
     DashboardQueryTypes.QTR_YOY3_LAG2,
     DashboardQueryTypes.QTR_LEVELS_LAG0,
     DashboardQueryTypes.QTR_LEVELS_LAG1,
     DashboardQueryTypes.QTR_LEVELS_LAG2,
 ]

 export const ContainsPeerIndices = [
     DashboardQueryTypes.WEEKLY_YOY,
     DashboardQueryTypes.WEEKLY_YOY2,
     DashboardQueryTypes.QTR_YOY_LAG0
 ]

 export const DailyStackAlignmentDependentCharts = [
     DashboardQueryTypes.DAILY_STACK_SW_WW,
     DashboardQueryTypes.DAILY_STACK_SW_US,
     DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES,
     DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES,
     DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES,
     DashboardQueryTypes.DAILY_STACK_SM_SALES,
     DashboardQueryTypes.DAILY_STACK_CC_BASELINE,
 ]

export const listOfListOfDashboardQueriesWithPriorities = [
    [DashboardQueryTypes.IDIO],
    [DashboardQueryTypes.WEEKLY_YOY],
    [DashboardQueryTypes.WEEKLY_YOY2],
    [
        DashboardQueryTypes.QTR_YOY_LAG0,
        DashboardQueryTypes.QTR_YOY2_LAG0
    ],
    [
        DashboardQueryTypes.PROFIT_YOY_LAG0
    ],
    [
        DashboardQueryTypes.PROFIT_YOY2_LAG0
    ],
    [
        DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE
    ],
    [
        DashboardQueryTypes.QTR_LEVELS_LAG0

    ],
    [
        DashboardQueryTypes.PRICING_YOY_LAG0,
        DashboardQueryTypes.PRICING_YOY2_LAG0
    ],
    [
        DashboardQueryTypes.CORRELATION_REPORT,
        DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX,
        DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX
    ],
    [
        DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0,
        DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0,
        DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0,
        DashboardQueryTypes.WEEKLY_STACK_GT_WW,
        DashboardQueryTypes.WEEKLY_STACK_GT_US
    ],
    [
        DashboardQueryTypes.WEEKLY_YOY3,
        DashboardQueryTypes.WEEKLY_YOY4,
        DashboardQueryTypes.WEEKLY_YOY5,
        DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0,
        DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0,
        DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0,
        DashboardQueryTypes.WEEKLY_LEVELS,
        DashboardQueryTypes.DAILY_YOY,
        DashboardQueryTypes.PROFIT_YOY_LAG1,
        DashboardQueryTypes.PROFIT_YOY_LAG2,
        DashboardQueryTypes.PRICING_YOY_LAG1,
        DashboardQueryTypes.PRICING_YOY_LAG2,
        DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0,
        DashboardQueryTypes.PROFIT_YOY2_LAG1,
        DashboardQueryTypes.PROFIT_YOY2_LAG2,
        DashboardQueryTypes.PRICING_YOY2_LAG1,
        DashboardQueryTypes.PRICING_YOY2_LAG2,
        DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0,
        DashboardQueryTypes.QTR_YOY_LAG1,
        DashboardQueryTypes.QTR_YOY_LAG2,
        DashboardQueryTypes.QTR_YOY2_LAG1,
        DashboardQueryTypes.QTR_YOY2_LAG2,
        DashboardQueryTypes.QTR_YOY3_LAG0,
        DashboardQueryTypes.QTR_YOY3_LAG1,
        DashboardQueryTypes.QTR_YOY3_LAG2,
        DashboardQueryTypes.QTR_LEVELS_LAG1,
        DashboardQueryTypes.QTR_LEVELS_LAG2,
        DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK,
        DashboardQueryTypes.WEEKLY_DYN_SW_STACK,
        DashboardQueryTypes.WEEKLY_STACK_SW_WW,
        DashboardQueryTypes.DAILY_STACK_SW_WW,
        DashboardQueryTypes.WEEKLY_STACK_SW_US,
        DashboardQueryTypes.DAILY_STACK_SW_US,
        DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES,
        DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES,
        DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS,
        DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES,
        DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES,
        DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS,
        DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES,
        DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES,
        DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS,
        DashboardQueryTypes.WEEKLY_STACK_SM_SALES,
        DashboardQueryTypes.DAILY_STACK_SM_SALES,
        DashboardQueryTypes.DAILY_STACK_CC_BASELINE,
        DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US,
        DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US,
        DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US,
        DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW,
        DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW,
        DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW,
        DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US,
        DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US,
        DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US,
        DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW,
        DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW,
        DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW,
        DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID,
    ],
    [
        //Daily aligned series:
        DashboardQueryTypes.DAILY_STACK_SW_WW_ALIGNED,
        DashboardQueryTypes.DAILY_STACK_SW_US_ALIGNED,
        DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES_ALIGNED,
        DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES_ALIGNED,
        DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES_ALIGNED,
        DashboardQueryTypes.DAILY_STACK_SM_SALES_ALIGNED,
        DashboardQueryTypes.DAILY_STACK_CC_BASELINE_ALIGNED,
        DashboardQueryTypes.DAILY_YOY_ALIGNED
    ],
]

let cache = {};

export function findIndexOfList(queryString) {
    let result;

    if (cache[queryString] !== undefined) {
        result = cache[queryString];
    } else {
        listOfListOfDashboardQueriesWithPriorities.some((list, idx) => {
            if (list.indexOf(queryString) !== -1) {
                result = idx;
                return true;
            }
        });

        // store the result into the cache
        cache[queryString] = result;
    }

    return result;
}

export const getPriorityForQueryType = queryType => {
    const availablePriority = findIndexOfList(queryType);
    if (availablePriority === null || availablePriority === undefined) {
        console.warn('Scheduling priority has not been defined for the queryType: ' + queryType);
        return 1;
    }
    return availablePriority;
}

export const VPT_GRID = 'VPT_GRID';


export const COMPACT_VIEW_QUERY_LIST = [
    DashboardQueryTypes.WEEKLY_YOY,
    DashboardQueryTypes.QTR_YOY_LAG0,
    DashboardQueryTypes.PROFIT_YOY_LAG0,
    DashboardQueryTypes.WEEKLY_YOY2,
    DashboardQueryTypes.QTR_YOY2_LAG0,
    DashboardQueryTypes.PROFIT_YOY2_LAG0,
    DashboardQueryTypes.IDIO,
    DashboardQueryTypes.QTR_LEVELS_LAG0,
    VPT_GRID
]