import {ExtraWeekIndicator} from "../components/ExtraWeekIndicator";
import {useContext} from "react";
import {generateLinkForOracleClickout} from "../../studio_high_chart/utilityFunctions";
import {Link} from "react-router-dom";
import {TickerWatchListContext} from "../commonContexts/TickerWatchListProvider";
import {
    getCompanyProfileInfoComponent,
    getShortInterestComponent,
    washSaleRenderingComponent
} from "../commonUtils/WatchListBarUtilFunctions";
import WaitListMarker from "../../stock_grades/WLMarker";
import {NextEarningsDateContext} from "../commonContexts/NextEarningsDateProvider";
import {BrandDropdown} from "../components/BrandDropdown";
import useClickoutProviderHook from "../commonHooks/ClickoutProviderHook";
import {useFactorWeightsProviderHook} from "../commonHooks/FactorWeightsProviderHook";
import {Tooltip} from 'react-tooltip';
import {generateFactorWeightsTableFromData} from "../../line_chart/FactorWeightTable";
import {ListSelectionBar} from "./ListSelectionBar";
import {RefreshContext} from "../commonContexts/RefreshContextProvider";

const enabledLinkStyleGT = {};
const disabledLinkStyleGT = {pointerEvents: "none", opacity: 0.6, color: "black"};

export const TickerBar = ({activeTick, brand, setBrand, showDataLag, showViewControls, showPositionsTooltips}) => {

    const {refresh} = useContext(RefreshContext);

    const {washSaleInfo, shortInterestInfo, positionInfo, companyProfileInfo} = useContext(TickerWatchListContext);
    const {earningsInfo, daysToEarning} = useContext(NextEarningsDateContext);

    const {
        clickOutUrl: clickoutUrl_us,
        loading: loading_us,
        make3Y: make3Y_us,
        make4Y: make4Y_us,
        make5Y: make5Y_us
    } = useClickoutProviderHook(activeTick.id, brand.id, "GT_US", refresh);

    const {
        clickOutUrl: clickoutUrl_ww,
        loading: loading_ww,
        make3Y: make3Y_ww,
        make4Y: make4Y_ww,
        make5Y: make5Y_ww
    } = useClickoutProviderHook(activeTick.id, brand.id, "GT_WW", refresh);

    const {
        clickOutUrl: clickoutUrl_AS_overview,
        loading: loading_AS_overview,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ALPHASENSE_OVERVIEW", refresh);

    const {
        clickOutUrl: clickoutUrl_AS_vpt,
        loading: loading_AS_vpt,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ALPHASENSE_VPT", refresh);

    const {
        clickOutUrl: clickoutUrl_ER_cohort,
        loading: loading_ER_cohort,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ER_COHORT", refresh);

    const {
        clickOutUrl: clickoutUrl_ER_trends,
        loading: loading_ER_trends,
    } = useClickoutProviderHook(activeTick.id, brand.id, "ER_TRENDS", refresh);

    const {
        clickOutUrl: clickoutUrl_SM_cohort,
        loading: loading_SM_cohort,
    } = useClickoutProviderHook(activeTick.id, brand.id, "SM_COHORT", refresh);

    // Data fetching and tooltip state management for factorWeights table.
    const {factorWeights, loading: loading_factors} = useFactorWeightsProviderHook(activeTick.id, refresh);

    const colorGetter = num => num >= 0 ? "green" : "red";
    const numberFormatter = num => {
        if (num >= 0) {
            return num.toFixed(0);
        } else {
            return '(' + Math.abs(num).toFixed(0) + ')';
        }
    }
    let positionTextComponent;

    if (positionInfo === undefined) {
        positionTextComponent = <span>Loading...</span>;
    } else if (positionInfo === null || positionInfo === "NO_POSITION" || positionInfo.position === 0) {
        positionTextComponent =
            <span style={{color: "#000000"}}>NO_POSITION</span>
    } else {
        let nav = positionInfo.eodMarketValue / positionInfo?.ratioOfNav;
        let bpsStock = positionInfo.stockPosition?.ratioOfNav * 10000;
        let bpsOptionsMax = (positionInfo?.callOptions.map(p => p.eodDeltaDollars / Math.abs(p?.delta)).reduce((a, b) => a + b, 0)
            + positionInfo?.putOptions.map(p => p.eodDeltaDollars / Math.abs(p?.delta)).reduce((a, b) => a + b, 0)) * 10000 / nav;
        let bpsOptionsCurrent = (positionInfo.callOptions.map(p => p.eodDeltaDollars).reduce((a, b) => a + b, 0)
            + positionInfo.putOptions.map(p => p.eodDeltaDollars).reduce((a, b) => a + b, 0)) * 10000 / nav;
        let bpsOptionsMarketValue = (positionInfo.callOptions.map(p => p?.ratioOfNav * Math.sign(p?.delta)).reduce((a, b) => a + b, 0)
            + positionInfo.putOptions.map(p => p?.ratioOfNav * Math.sign(p?.delta)).reduce((a, b) => a + b, 0)) * 10000;
        let bpsIncludingDelta = bpsStock + bpsOptionsCurrent;
        let profitValue = positionInfo.unrealizedPnl * 100 / Math.abs(positionInfo.eodMarketValue - positionInfo.unrealizedPnl);
        let profitText;
        if (profitValue > 0) {
            profitText = profitValue.toFixed(2) + '% ';
        } else {
            profitText = '(' + Math.abs(profitValue).toFixed(2) + '%) ';
        }

        positionTextComponent =
            <span>
                <span title="Bps of stock and options delta over NAV" style={{
                    color: colorGetter(bpsIncludingDelta)
                }}>{numberFormatter(bpsIncludingDelta)}</span> = <span
                title="Bps of stock market value over NAV"
                style={{color: colorGetter(bpsStock)}}>{numberFormatter(bpsStock)}</span> + <span
                title="Bps of current options delta over NAV" style={{
                color: colorGetter(bpsOptionsCurrent)
            }}>{numberFormatter(bpsOptionsCurrent)}</span> | <span>
                    <span title="Bps of options with maximum delta over NAV" style={{
                        color: colorGetter(bpsOptionsMax)
                    }}>{numberFormatter(bpsOptionsMax)}</span> <span
                title="Bps of options premium over NAV" style={{
                color: colorGetter(bpsOptionsMarketValue)
            }}>{numberFormatter(bpsOptionsMarketValue)}</span>
                </span> | <span
                title="PnL percentage" style={{
                color: colorGetter(profitValue)
            }}>{profitText}</span></span>;
    }

    const handleClick = (e, us, ww, func_us, func_ww, country) => {
        e.preventDefault();
        window.open(func_us(us, country).replaceAll("+", "%2B"), '_blank');
        window.open(func_ww(ww, "").replaceAll("+", "%2B"), '_blank');
    }


    return (
        <>
        <nav style={{
                display: 'flex',
                backgroundColor: '#CCD3D9',
                alignItems: 'center',
                padding: '0px',
                height: '40px'
            }}>
                {activeTick.id != null && <>
                    <ExtraWeekIndicator activeTick={activeTick}/>
                    <Link to={generateLinkForOracleClickout(activeTick.name)} target={"_blank"}
                          style={{textDecoration: "underline", fontSize: 25}}>
                        {activeTick.name}
                    </Link>
                    <span className={'gt-display-bar'}>
                        {positionTextComponent}
                    </span>
                </>}
                <span className={'gt-display-bar'}>{washSaleRenderingComponent(washSaleInfo)}</span>
                <span className={'gt-display-bar'}
                      title={'As of ' + earningsInfo.asOf}>{earningsInfo.nextEarningsDate} | {daysToEarning} </span>
                <div>
                    <BrandDropdown activeTick={activeTick} activeBrand={brand} setActiveBrand={setBrand}
                                   popout={false}/>
                </div>
            <span className={'gt-display-bar'}>
                    GT: (<a
                style={(loading_us || loading_ww) ? disabledLinkStyleGT : enabledLinkStyleGT}
                href="#"
                onClick={(e) => handleClick(e, clickoutUrl_us, clickoutUrl_ww, make3Y_us, make3Y_ww, "US")}
                target={"_blank"}>
                                        3Y
                        </a>, <a
                style={(loading_us || loading_ww) ? disabledLinkStyleGT : enabledLinkStyleGT}
                href="#"
                onClick={(e) => handleClick(e, clickoutUrl_us, clickoutUrl_ww, make4Y_us, make4Y_ww, "US")}
                target={"_blank"}>
                                        4Y
                        </a>)
                    AS: (
                    <a style={loading_AS_overview ? disabledLinkStyleGT : enabledLinkStyleGT}
                       href={clickoutUrl_AS_overview} target={"_blank"}>O</a>
                    , <a style={loading_AS_vpt ? disabledLinkStyleGT : enabledLinkStyleGT}
                         href={clickoutUrl_AS_vpt} target={"_blank"}>V</a>), <a style={loading_ER_trends ? disabledLinkStyleGT : enabledLinkStyleGT}
                       href={clickoutUrl_ER_trends} target={"_blank"}>ER</a>, <a style={loading_ER_cohort ? disabledLinkStyleGT : enabledLinkStyleGT}
                       href={clickoutUrl_ER_cohort} target={"_blank"}>ER(R)</a>, <a style={loading_SM_cohort ? disabledLinkStyleGT : enabledLinkStyleGT}
                href={clickoutUrl_SM_cohort} target={"_blank"}>SM</a>
                </span>
            <a id="anchor-element" className="button-factors">Factors</a>
            {!loading_factors && <Tooltip
                anchorSelect="#anchor-element"
                    place='bottom'
                    variant='light'
                    style={{zIndex: 1000}}
                    opacity={1}
                >
                    {/*{generateFactorWeightsTableFromData(loading_factors, factorWeights, false)}*/}
                This will updated to refer to Omega Point factor loadings data soon
                </Tooltip>}

                <a id="anchor-element-si" className="button-factors">SI</a>
                {<Tooltip
                    anchorSelect="#anchor-element-si"
                    place='bottom'
                    variant='light'
                    style={{zIndex: 1000}}
                    opacity={1}
                >
                    {getShortInterestComponent(shortInterestInfo)}
                </Tooltip>}
            <a id="anchor-element-profile-info" className="button-factors">Info</a>
            {<Tooltip
                anchorSelect="#anchor-element-profile-info"
                place='bottom'
                variant='light'
                style={{zIndex: 1000}}
                opacity={1}
            >
                {getCompanyProfileInfoComponent(companyProfileInfo)}
            </Tooltip>}
            {showPositionsTooltips && <ListSelectionBar
                    activeTick={activeTick}
                    showDataLag={showDataLag}
                    showViewControls={showViewControls}
                    tooltipMode={true}
                    brand={brand}
                    />}
                <WaitListMarker activeTick={activeTick}/>
            </nav>
        </>
    )
}
