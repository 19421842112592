import {useContext, useEffect, useState} from "react";
import {BRAND_NAME, TICKER_NAME} from "./DashboardConstants";
import {getBrands, getTickers} from "../../../api/data/DataProvider";
import {macroTickerOption} from "./DashboardReport";
import {SplitContextsConsumerWrapper, ViewContextProvider} from "./views/ViewContext";
import {DashboardApiContextStore, DashboardReportContextProvider} from "./DashboardReportContextProvider";
import {getChartForQueryType, getYearsToShowForQueryType} from "../../../api/data/QueryTypeToChartMapping";

export const CHART = 'CHART';

export const SingleChartRenderer = () => {

    const params = new URLSearchParams(window.location.search);
    const [tickName, setTickName] = useState(params.get(TICKER_NAME));
    const [brandName, setBrandName] = useState(params.get(BRAND_NAME));
    const [chart, setChart] = useState(params.get(CHART.toLowerCase()));
    const [combineAxes] = useState(params.get('combineAxes') === 'true');
    let [tickerOptions, setTickerOptions] = useState([]);
    const [tickId, setTickId] = useState(null);
    const [brandId, setBrandId] = useState(null);

    useEffect(() => {
        getTickers().then((res) => {
            let activeTickers = res.data.map(tickResponse => {
                return {
                    value: tickResponse.tickerId,
                    label: tickResponse.tick
                }
            });

            activeTickers.push(macroTickerOption);
            setTickerOptions(activeTickers)
        })
            .catch((err) => {
                console.error("Error fetching Tickers", err)
            })
    }, [])

    useEffect(() => {
        if(tickName !== null){
            const currentTicker = tickerOptions.find(ticker => ticker.label === tickName);
            if (!currentTicker) {
                return;
            }
            setTickId(currentTicker.value);
            if(currentTicker && brandName !== null){
                getBrands(currentTicker.value).then((res) => {
                    let activeBrand = res.data.find(brand => brand.brandName === brandName);
                    setBrandId(activeBrand.brandId);
                }).catch(err =>
                    console.log("Failed to preload brand for ticker", err)
                )
            }
        }
    }, [tickerOptions]);

    const tick = {
        name: tickName,
        id: tickId
    };
    const brand = {
        name: brandName,
        id: brandId
    };


    return (
        <>
            {tick.id !== null && <ViewContextProvider tick={tick} brand={brand} combineAxes={combineAxes}>
                <DashboardReportContextProvider
                    brand={brand}
                    tick={tick}
                    customQueryList={[chart?.toUpperCase()]}>
                        < SplitContextsConsumerWrapper
                            queryType={chart}
                            RawComponent={getChartForQueryType(chart)}
                            activeTicker={tick?.name}
                            activeBrandName={brand?.name}
                            activeBrand={brand.id}
                            /*chartHeightOverride={chartHeight.current+'px'}
                            chartWidth={chartWidth.current+'px'}*/
                            yearsToShow={getYearsToShowForQueryType(chart)}
                        />
                </DashboardReportContextProvider>
            </ViewContextProvider>}
        </>
    )
}