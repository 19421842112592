import Measure from "react-measure";
import {VPTGrid} from "../vpt/VPTGrid";
import {useEffect, useRef, useState} from "react";
import {StudioHistoricalPriceChart} from "../studio_high_chart/StudioHistoricalPriceChart";
import {StudioIntradayPriceChart} from "../studio_high_chart/StudioIntradayPriceChart";

const ESTIMATED_INITIAL_HEIGHT = 500;
const ESTIMATED_INITIAL_WIDTH = 500;

export const FinDataGrid = () => {
    const chartHeight = useRef(ESTIMATED_INITIAL_HEIGHT);
    const chartWidth = useRef(ESTIMATED_INITIAL_WIDTH);

    // State to track dimensions for preventing too frequent updates
    const [dimensions, setDimensions] = useState({
        height: ESTIMATED_INITIAL_HEIGHT,
        width: ESTIMATED_INITIAL_WIDTH
    });

    const [dimensionsMeasured, setDimensionsMeasured] = useState(false);

    useEffect(() => {
        const unsetDimensionsMeasured = () => setDimensionsMeasured(false);
        // Add a resize listener to the window
        window.addEventListener("resize", unsetDimensionsMeasured);

        // Clean up the resize event listener on component unmount
        return () => window.removeEventListener("resize", unsetDimensionsMeasured);
    }, [])

    return (
        <>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                gap: "10px",
                minHeight: ESTIMATED_INITIAL_HEIGHT,
                minWidth: ESTIMATED_INITIAL_WIDTH,
            }}>
                <div style={{ gridRow: "1", gridColumn: "1" }}>
                    <VPTGrid onlyGridView={true} gridHeight={dimensions.height} gridWidth={dimensions.width} />
                </div>
                <div style={{ gridRow: "1", gridColumn: "2" }}>
                    < Measure
                        bounds
                        onResize={(contentRect) => {
                            if(dimensionsMeasured) return;
                            setDimensionsMeasured(true);
                            const { height, width } = contentRect.bounds;

                            // Adding tolerance to avoid small dimension changes triggering re-renders
                            if (
                                Math.abs(height - chartHeight.current) > 1 ||
                                Math.abs(width - chartWidth.current) > 1
                            ) {
                                chartHeight.current = height;
                                chartWidth.current = width;

                                // Update the state to reflect the new dimensions
                                setDimensions({
                                    height: height || ESTIMATED_INITIAL_HEIGHT,
                                    width: width || ESTIMATED_INITIAL_WIDTH,
                                });
                            }
                        }}>
                        {({ measureRef }) => (
                            <div ref={measureRef} key={`findata-grid-component-intradayPrice`}>
                                <div>
                                    <StudioIntradayPriceChart chartHeight={dimensions.height} chartWidth={dimensions.width} />
                                </div>
                            </div>
                        )}
                    </Measure>
                </div>
                <div style={{ gridRow: "2", gridColumn: "1 / span 2" }}>
                    <StudioHistoricalPriceChart chartHeight={dimensions.height} chartWidth={dimensions.width * 2} />
                </div>
            </div>
        </>
    );
};