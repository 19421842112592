import {CAlert, CButton, CSpinner} from "@coreui/react";
import {message} from "antd";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsStock from "highcharts/modules/stock";
import React, {memo, useEffect, useRef, useState} from "react";
import Banner from "react-js-banner";
import {auditUser, getBrands, getIndexWeights, getPreferences, getTickers} from "../../../api/data/DataProvider";
import ClickOut from "../../clickout/ClickOut";
import InsiderTransTable from "../../insider_transactions/InsiderTransTable";
import DailyCharts from "../../line_chart/DailyCharts";
import DynAltGtStackChartsWrapper from "../../line_chart/alt_gt_stack/DynAltGtStackChartsWrapper";
import IdioChart from "../../line_chart/IdioChart";
import QuarterCharts, {ApiContextConnectedQuarterChart} from "../../line_chart/QuarterCharts";
import StackCharts from "../../line_chart/StackCharts";
import WeeklyCharts from "../../line_chart/WeeklyCharts";
import DynSwStackChartsWrapper from "../../line_chart/sw_dynamic_stack/DynSwStackChartsWrapper";
import SentieoBar from "../../stock_grades/SentieoBar";
import StockGradesTable from "../../stock_grades/StockGradesTable";
import {ContextConnectedCorrelationReport} from "../correlationReort/CorrelationReport";
import IndexDetails from '../IndexDetails';
import MacroReport from "../MacroReport";
import NavBar from "../NavBar";
import ExpandModal from "../../ExpandModal";
import {APIContextConnectedMutiChartSwitcher} from "../../line_chart/MultiChartSwitcher";
import {useNavigate} from 'react-router-dom';
import {BRAND_ID, BRAND_NAME, MINI_VIEW, TICKER_NAME} from "./DashboardConstants";
import {
    COMPACT_VIEW_QUERY_LIST,
    DashboardQueryTypes,
    MINI_VIEW_REQUIRED_QUERIES
} from "../../../api/data/DashboardQueryTypes";
import {DashboardReportContextProvider} from "./DashboardReportContextProvider";
import Measure from "react-measure";
import {SplitContextsConsumerWrapper, ViewContextProvider} from "./views/ViewContext";
import CompositeIndexDetails from "../CompositeIndexDetails";
import {VariableSizeList} from "react-window";
import {ListsProvider} from "../commonContexts/ListsProvider";
import _ from "lodash";
import {CorrelationReportProvider} from "../CorrelationReportContextProvider";
import {AltGtStackContextProvider} from "../../line_chart/alt_gt_stack/AltGtStackContext";
import {DynSWStackProvider} from "../../line_chart/sw_dynamic_stack/DynSWStackContext";
import GtTopRegionsGrid from "../../line_chart/GtTopRegionsGrid";
import {monitor} from "../../../event_handler/KeyPressMonitor";
import {TickerWatchListProvider} from "../commonContexts/TickerWatchListProvider";
import {NextEarningsDateProvider} from "../commonContexts/NextEarningsDateProvider";
import {DashboardReportCompactView} from "../compactView/DashboardReportCompactView";
import {MiniView2x2} from "./MiniView2x2";
import {VPTGridProvider} from "../../vpt/VPTGridProvider";
import {ViewsSettingsModal} from "../views-menu/ViewsSettingsModal";
import {RefreshContextProvider} from "../commonContexts/RefreshContextProvider";
import {WTDStacksComponent} from "./wtdStacks/WTDStacksComponent";
import { notification } from 'antd';

const queryTypeToIndexCacheForScrolling = {};
export const macroTickerOption = {
    value: -1,
    label: 'MACRO'
};


export const DASHBOARD_GRID = 'DASHBOARD_GRID';
const DashboardReport = () => {
    monitor.enableHotKeys(true);
    //Logic for fetching and storing the ticker's info:
    let [tickerOptions, setTickerOptions] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();

    const SCROLL_POSITION = "start";


    useEffect(() => {
        getTickers().then((res) => {
            let activeTickers = res.data.map(tickResponse => {
                return {
                    value: tickResponse.tickerId,
                    label: tickResponse.tick
                }
            });

            activeTickers.push(macroTickerOption);
            setTickerOptions(activeTickers)
        })
            .catch((err) => {
                console.error("Error fetching Tickers", err)
            })
    }, [])

    const [browserParamsUsed, setBrowserParamsUsed] = useState(false);

    const searchParams = new URLSearchParams(document.location.search)

    const [auth, setAuth] = useState(true);
    const [tick, setTick] = useState({
        name: "Select Ticker",
        id: null
    });
    const [brand, setBrand] = useState({
        name: "Select Brand",
        id: null
    })

    useEffect(() => {
        if(brand.id != null)
            setBrand({
                name: "Select Brand",
                id: null
            })
    }, [tick]);

    const [preferences, setPreferences] = useState(null);
    const [indexWeights, setIndexWeights] = useState(null);
    const [ccIndexWeights, setCcIndexWeights] = useState(null);

    const [showMacroView, setShowMacroView] = useState(false);
    const showMacroViewRef = useRef(false);


    const toggleMiniView = () => {
        setShowMiniView(prev => !prev);
    }

    let navigate = useNavigate();
    const [showMiniView, setShowMiniView] = useState(searchParams.get(MINI_VIEW) === true.toString());
    const [miniViewMode3x3, setMiniViewMode3x3] = useState(true);

    const toggleMiniViewMode = () => {
        if (!showMiniView) setShowMiniView(true);
        setMiniViewMode3x3(prev => !prev);
    }


    useEffect(() => {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ","
            }
        });
    }, []);

    useEffect(() => {
        if (!browserParamsUsed) return;
        const params = new URLSearchParams(window.location.search);

        // Add or update 'ticker' and 'brand' query parameters
        // All checks should validate that id is not null, since we don't want 'Select Brand' to be visible in params.
        if (tick && tick.id && tick.name) params.set(TICKER_NAME, tick.name);
        if (brand && brand.id && brand.name) {
            params.set(BRAND_NAME, brand.name);
        } else {
            params.delete(BRAND_ID);
            params.delete(BRAND_NAME);
        }
        params.set(MINI_VIEW, showMiniView.toString());
        // Update URL with new search parameters
        navigate(window.location.pathname + '?' + params.toString());
    }, [tick, brand, navigate, browserParamsUsed, showMiniView]);

    const [isInvalidTicker, setIsInvalidTicker] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (tickerOptions.length === 0) return;
        let preFetchTickerName = params.get(TICKER_NAME)?.toUpperCase();
        if (preFetchTickerName != null) {
            const currentTicker = tickerOptions.find(ticker => ticker.label === preFetchTickerName);
            if (!currentTicker) {
                setIsInvalidTicker(true);
                return;
            }
            const preFetchBrandName = params.get(BRAND_NAME);
            if (currentTicker && preFetchBrandName != null) {
                getBrands(currentTicker.value).then((res) => {
                    let activeBrand = res.data.find(brand => brand.brandName.toLowerCase() === preFetchBrandName.toLowerCase());
                    setBrand({
                        id: activeBrand.brandId,
                        name: activeBrand.brandName
                    });
                }).catch(err =>
                    console.log("Failed to preload brand for ticker", err)
                )
            }
            setTick({
                id: currentTicker.value,
                name: currentTicker.label
            });
        }
        setBrowserParamsUsed(true);
    }, [tickerOptions]);

    useEffect(() => {
        if (tick.id == null) return;
        getPreferences(tick.name, brand.id)
            .then((res) => {
                setPreferences(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching preferences: " + err);
            });

        let user = localStorage.getItem('studio_internal_authenticated_user');
        auditUser(user, "DASHBOARD_REPORT", tick.name).then((res) => {
        }).catch((e) => {
        });
    }, [tick, brand]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.key === 'm') {
                e.preventDefault();
                showMacroViewRef.current = !showMacroViewRef.current
                setShowMacroView(showMacroViewRef.current);
            }

            if (e.ctrlKey && e.key === 's') {
                e.preventDefault();
                setShowMiniView((prev) => !prev);
            }
        };

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, []);

    /*const toggleIsCCCombineAxis = () => {
        // When turing it on or off, combine:
        if(isCombineCCRevAxis){
            setCombineCCRevAxis(false);
            setShowWeeklyCCSeries(false);
        } else {
            setCombineCCRevAxis(true);
            setShowWeeklyCCSeries(true);
        }
    }*/
    // Ideally, this should all be managed in the DashboardReport context instead of here:
    //Todo: Create a query group and priorities for these as well:
    useEffect(() => {
        if (tick.id == null) return;
        getIndexWeights(tick.name)
            .then((res) => {
                setIndexWeights(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching index weights: " + err);
                setIndexWeights(null);
            });

    }, [tick]);

    useEffect(() => {
        if (tick.id == null) return;
        getIndexWeights(tick.name, 'YOY_CC')
            .then((res) => {
                setCcIndexWeights(res.data);
            })
            .catch((err) => {
                console.log("Got error while fetching index weights: " + err);
                setCcIndexWeights(null);
            });

    }, [tick]);

    // Update Title
    useEffect(() => {
        let documentTitle = "Oracle - ";
        if (tick.id != null) {
            documentTitle = documentTitle + tick.name;
        }
        if (brand.id != null) {
            documentTitle = documentTitle + " - " + brand.name;
        }

        document.title = documentTitle;
    }, [tick, brand]);


    const weeklyChartsComponentHeight = 600;
    const idioChartsComponentHeight = 600;
    const dailyChartsComponentHeight = 600;
    const quarterChartsComponentHeight = 850;
    const modalChartsComponentHeight = 950;
    const stackChartHeight = brand.id == null ? 350 : 500;

    const bannerElement = brand.id == null ? {height: 0, component: <></>} :
        {
            height: 50,
            component: <Banner
                id={'KPI-Mosiacs-info-banner'}
                title={"Additional KPI's Available for QTR mosaics below (not mapped to any specific brand), can be found in end of legend with prefix 'bbg_kpi: '"}/>
        };

    const stockGradesTableComponent = brand.id == null ? {
        height: 600,
        refForwardingOverride: true,
        component: <StockGradesTable activeTicker={tick.name}/>
    } : {height: 0, component: <></>};

    const insiderTransactionsTableComponent = brand.id == null ? {
        height: 600,
        refForwardingOverride: true,
        component: <InsiderTransTable activeTicker={tick.name}/>
    } : {height: 0, component: <></>};


    const fullDashBoardItemsList = [
        {
            height: 3000,
            component: <DashboardReportCompactView tick={tick} brand={brand} modifyTick={setTick} setBrand={setBrand} />,
            queryType: DASHBOARD_GRID
        },
        {
            height: 75,
            component: <NextEarningsDateProvider activeTick={tick}> <SentieoBar activeTick={tick}/>
            </NextEarningsDateProvider>
        },
        {
            height: 75,
            component: <CompositeIndexDetails tickerName={tick.name}/>
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY}

            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY2}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY2
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY3}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY3
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY4}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY4
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_YOY5}
            />,
            queryType: DashboardQueryTypes.WEEKLY_YOY5
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX}
            />,
            queryType: DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_INDEX
        },
        {
            height: idioChartsComponentHeight,
            component: <IdioChart
                activeTicker={tick.name}
                queryType={DashboardQueryTypes.IDIO}
                activeTickerId={tick.id}/>,
            queryType: DashboardQueryTypes.IDIO
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PROFIT_YOY_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PRICING_YOY_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PROFIT_YOY2_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_PRICING_YOY2_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0}
            />,
            queryType: DashboardQueryTypes.WEEKLY_TRANSACTIONS_YOY2_LAG0
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX}
            />,
            queryType: DashboardQueryTypes.WEEKLY_MULTI_YEAR_ORACLE_CC_GP_INDEX
        },
        {
            height: weeklyChartsComponentHeight,
            component: <WeeklyCharts activeTicker={tick.name}
                                     activeBrand={brand.id}
                                     activeBrandName={brand.name}
                                     queryType={DashboardQueryTypes.WEEKLY_LEVELS}
            />,
            queryType: DashboardQueryTypes.WEEKLY_LEVELS
        },
        {
            height: dailyChartsComponentHeight,
            component: <DailyCharts activeTicker={tick.name} activeBrand={brand.id} activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_YOY}
                                    yearsToShow={1}
            />,
            queryType: DashboardQueryTypes.DAILY_YOY,
            style: {marginBottom: 10}
        },
        bannerElement,
        {
            height: 50,
            component: <IndexDetails name='Total Index' weights={indexWeights?.weightDetails?.weights}/>
        },
        {
            height: 50,
            component: <IndexDetails name='CC Index' weights={ccIndexWeights?.weightDetails?.weights}/>
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY_LAG1}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY_LAG1
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY_LAG2}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY_LAG2
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PROFIT_YOY_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PROFIT_YOY_LAG0, DashboardQueryTypes.PROFIT_YOY_LAG1, DashboardQueryTypes.PROFIT_YOY_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>,
            queryType: DashboardQueryTypes.PROFIT_YOY_LAG0
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PRICING_YOY_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PRICING_YOY_LAG0, DashboardQueryTypes.PRICING_YOY_LAG1, DashboardQueryTypes.PRICING_YOY_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>,
            queryType: DashboardQueryTypes.PRICING_YOY_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts activeTicker={tick.name}
                                      activeBrand={brand.id}
                                      activeBrandName={brand.name}
                                      queryType={DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0}
                                      yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_TRANSACTIONS_YOY_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY2_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY2_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY2_LAG1}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY2_LAG1
        }, {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY2_LAG2}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY2_LAG2
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PROFIT_YOY2_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PROFIT_YOY2_LAG0, DashboardQueryTypes.PROFIT_YOY2_LAG1, DashboardQueryTypes.PROFIT_YOY2_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>
        },
        {
            height: modalChartsComponentHeight,
            component: <ExpandModal
                outerContent={<ApiContextConnectedQuarterChart
                    activeTicker={tick.name}
                    activeBrand={brand.id}
                    activeBrandName={brand.name}
                    queryType={DashboardQueryTypes.PRICING_YOY2_LAG0}
                    yearsToShow={5}/>}
                modalContent={
                    <APIContextConnectedMutiChartSwitcher ChartType={QuarterCharts}
                                                          queryTypes={[DashboardQueryTypes.PRICING_YOY2_LAG0, DashboardQueryTypes.PRICING_YOY2_LAG1, DashboardQueryTypes.PRICING_YOY2_LAG2]}
                                                          activeTicker={tick.name}
                                                          activeBrand={brand.id} activeBrandName={brand.name}
                                                          yearsToShow={5}/>
                }/>
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_TRANSACTIONS_YOY2_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY3_LAG0}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY3_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY3_LAG1}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY3_LAG1
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_YOY3_LAG2}
                yearsToShow={5}/>,
            queryType: DashboardQueryTypes.QTR_YOY3_LAG2
        }, {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_LEVELS_LAG0}
                yearsToShow={7}/>,
            queryType: DashboardQueryTypes.QTR_LEVELS_LAG0
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_LEVELS_LAG1}
                yearsToShow={7}/>,
            queryType: DashboardQueryTypes.QTR_LEVELS_LAG1
        },
        {
            height: quarterChartsComponentHeight,
            component: <QuarterCharts
                activeTicker={tick.name}
                activeBrand={brand.id}
                activeBrandName={brand.name}
                queryType={DashboardQueryTypes.QTR_LEVELS_LAG2}
                yearsToShow={7}/>,
            queryType: DashboardQueryTypes.QTR_LEVELS_LAG2
        },
        {
            height: 50,
            component: <ClickOut activeTickerId={tick.id} activeBrandId={brand.id} clickOutType={"GT_TERM_USAGE"}
                                 displayName={"GT Terms Used"}/>
        },
        {
            height: 75,
            component: <ClickOut activeTickerId={tick.id} activeBrandId={brand.id} clickOutType={"GT_WW"}
                                 displayName={"GT WW Trends Click"}/>,
            queryType: "GT WW Trends Click"
        },
        {
            height: 1000,
            component: <DynAltGtStackChartsWrapper activeTicker={tick.name} activeBrand={brand.id}
                                                   activeBrandName={brand.name}
                                                   queryType={DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK}
                                                   yearsToShow={1}
                                                   location={"WW"}/>,
            queryType: DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK
        },
        // Todo: state for this needs to be lifted out, when it unmounts, closes the opened/selected charts
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_GT_WW}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_GT_WW
        },
        {
            height: 50,
            component: <ClickOut activeTickerId={tick.id} activeBrandId={brand.id} clickOutType={"GT_US"}
                                 displayName={"GT US Trends Click"}/>
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_GT_US}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_GT_US,
            style: {marginBottom: 10}
        }, {
            height: 600,
            component: <GtTopRegionsGrid activeTicker={tick.name}
                                         activeBrand={brand.id}
                                         activeBrandName={brand.name}
                                         queryType={DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_GT_TOP_REGIONS_GRID
        },
        {
            height: 70,
            component: <DynSwStackChartsWrapper activeTicker={tick}
                                                queryType={DashboardQueryTypes.WEEKLY_DYN_SW_STACK}
                                                yearsToShow={1}
                                                location={"WW"}/>,
            queryType: DashboardQueryTypes.WEEKLY_DYN_SW_STACK
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_SW_WW}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_SW_WW
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_SW_WW}
                                    yearsToShow={1}
                                    preferences={preferences}/>,
            queryType: DashboardQueryTypes.DAILY_STACK_SW_WW
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_SW_US}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_SW_US
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_SW_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_SW_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_ER_ORION_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_ORION_TRANSACTIONS
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_ER_VELA_VELORUM_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_TRANSACTIONS
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_ER_VELA_GAMMA_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_TRANSACTIONS
        },
        {
            height: 75,
            component: <ClickOut activeTickerId={tick.id}
                                 activeBrandId={brand.id}
                                 clickOutType={"SM"}
                                 displayName={"SM Click"}/>
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_SM_SALES}
                                    yearsToShow={1}/>,
            queryType: DashboardQueryTypes.WEEKLY_STACK_SM_SALES
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_SM_SALES}
                                    yearsToShow={1}
                                    preferences={preferences}/>,
            queryType: DashboardQueryTypes.DAILY_STACK_SM_SALES
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.DAILY_STACK_CC_BASELINE}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.DAILY_STACK_CC_BASELINE
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_TOTAL_BASELINE
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_US
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DAU_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_APPT_APP_MAU_WW
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_US
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_US
        },
        {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DL_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.WEEKLY_STACK_ST_APP_DAU_WW
        }, {
            height: stackChartHeight,
            component: <StackCharts activeTicker={tick.name}
                                    activeBrand={brand.id}
                                    activeBrandName={brand.name}
                                    queryType={DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW}
                                    yearsToShow={1}
                                    preferences={preferences}
            />,
            queryType: DashboardQueryTypes.MONTHLY_STACK_ST_APP_MAU_WW
        },
        stockGradesTableComponent,
        insiderTransactionsTableComponent
    ]

    // This ref will store the ResizeObserver / Measure observed height for each of the LazyLoaded components:
    const rowHeights = useRef({});
    const listRef = useRef(null);

    function getItemSize(index) {
        // TradingView needs the size to be hardcoded:
        if (fullDashBoardItemsList[index].perpetualHeightOverride != null && fullDashBoardItemsList[index].perpetualHeightOverride === true) {
            return fullDashBoardItemsList[index].height;
        }
        let possibleHeight = rowHeights.current[index];
        // For initial render, use provided heuristic values:
        if (possibleHeight == null) {
            return fullDashBoardItemsList[index].height;
        }
        return possibleHeight;
    }

    const [correlReportVisibility, setCorrelReportVisibility] = useState(false);

    const scrollIndex = useRef(-1);

    const scrollToElement = (queryType) => {
        messageApi.open({
            type: 'loading',
            content: 'Scrolling',
            duration: 0.1
        }).then(() => {
            let index;
            // Try to lookup the item in the cache:
            if (queryTypeToIndexCacheForScrolling[queryType] != null) {
                // Cache hit:
                index = queryTypeToIndexCacheForScrolling[queryType];
            } else {
                index = _.findIndex(fullDashBoardItemsList, item => item.queryType === queryType);
                queryTypeToIndexCacheForScrolling[queryType] = index;
            }

            if (correlReportVisibility) {
                setCorrelReportVisibility(false);
                scrollIndex.current = index;
                return;
            }

            if (showMiniView) {
                setShowMiniView(false);
                scrollIndex.current = index;
                return;
            }

            // Todo: Figure the placement of item in hte viewport:
            listRef.current?.scrollToItem(index, SCROLL_POSITION);
        });

    }

    const scrollOffset = useRef(-1);
    const toggleCorrelReportVisibility = () => {
        messageApi.open({
            type: 'loading',
            content: 'Toggling Correlations',
            duration: 0.2
        }).then(() => {
            //get current state:
            if (!correlReportVisibility) {
                //Store the current scroll position:
                scrollOffset.current = listRef.current?.state.scrollOffset;
                setCorrelReportVisibility(true);
            } else {
                setCorrelReportVisibility(false)
            }
        })
    }


    useEffect(() => {
            if (!listRef.current) return;
            if (scrollIndex.current >= 0) {
                listRef.current?.scrollToItem(scrollIndex.current, SCROLL_POSITION);
                scrollIndex.current = -1;
            } else if (scrollOffset.current >= 0) {
                listRef.current?.scrollTo(scrollOffset.current)
                scrollOffset.current = -1;
            }
        },
        [listRef.current]);


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (window.isEnableHotKeys && !event.ctrlKey) {
                if (event.key === 'q') {
                    scrollToElement(DASHBOARD_GRID);
                } else if (event.key === 'w') {
                    toggleCorrelReportVisibility();
                } else if (event.key === 'e') {
                    scrollToElement(DashboardQueryTypes.QTR_YOY_LAG0);
                } else if (event.key === 'r') {
                    scrollToElement(DashboardQueryTypes.PROFIT_YOY_LAG0);
                } else if (event.key === 't') {
                    scrollToElement(DashboardQueryTypes.QTR_YOY2_LAG0);
                } else if (event.key === 'y') {
                    scrollToElement(DashboardQueryTypes.QTR_YOY3_LAG0);
                } else if (event.key === 'u') {
                    scrollToElement(DashboardQueryTypes.QTR_LEVELS_LAG0);
                } else if (event.key === 'i') {
                    scrollToElement("GT WW Trends Click");
                } else if (event.key === 'o') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_DYN_SW_STACK);
                } else if (event.key === 'p') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_ORION_SALES);
                } else if (event.key === '[') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_VELA_VELORUM_SALES)
                } else if (event.key === ']') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_ER_VELA_GAMMA_SALES);
                } else if (event.key === '\\') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_SM_SALES);
                } else if (event.key === 's') {
                    scrollToElement(DashboardQueryTypes.DAILY_STACK_CC_BASELINE);
                } else if (event.key === 'a') {
                    scrollToElement(DashboardQueryTypes.WEEKLY_STACK_APPT_APP_DL_US);
                }
            }

            if (event.ctrlKey && event.key === 't') {
                event.preventDefault();
                messageApi.open({
                    type: 'success',
                    content: 'Loading Week To Date Stacks',
                });
                setWtdStackModalOpen(prev => !prev);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [correlReportVisibility]);

    function setRowHeight(index, size) {
        listRef.current?.resetAfterIndex(0);
        if (rowHeights.current[index] === Math.round(size)) return;
        rowHeights.current = {...rowHeights.current, [index]: Math.round(size)};
    }

    const HeightObserverWrappedComponent = memo(({index, style}) => {
        // Todo: There is scope for optimizing the behaviour here, using single resizeObserver shared across all the components.
        const item = fullDashBoardItemsList[index];
        return <div id={item.queryType} style={style}>
            <Measure
                bounds
                onResize={(contentRect) => setRowHeight(index, contentRect.bounds.height)}>
                {({measureRef}) =>
                    <div ref={measureRef}>
                        <SplitContextsConsumerWrapper
                            queryType={item.queryType}
                            RawComponent={item.component}
                        />
                    </div>
                }
            </Measure>
        </div>
    })

    const [wtdStackModalOpen, setWtdStackModalOpen] = useState(false);

    return (
        <>
            <RefreshContextProvider>
                {contextHolder}
                {isInvalidTicker && <div>
                    <CAlert color="danger">
                        The ticker does not exist. Please validate the ticker name.
                        <br/>
                        <br/>
                        <CSpinner color="light"/>
                    </CAlert>
                </div>}
                {!isInvalidTicker && <div>
                    {auth == null && <CAlert color="info">Trying to authenticate the user, please wait...<br/><CSpinner
                        color="light"/></CAlert>}
                    {auth != null && !auth && <div>
                        <CAlert color="danger">
                            Authentication Failed ! Please check your api key and try again.
                            <br/>
                            <br/>
                            <CButton color="warning" onClick={() => window.location.reload()}>
                                Refresh Now
                            </CButton>
                        </CAlert>
                    </div>
                    }
                    {auth != null && !showMacroView && auth && <div>
                        {HC_exporting(Highcharts)}
                        {require("highcharts/modules/export-data")(Highcharts)}
                        {HighchartsStock(Highcharts)}
                        {tick.id == null && <>
                            <NavBar activeTick={tick}
                                    setActiveTick={setTick}
                                    activeBrand={brand}
                                    setActiveBrand={setBrand}
                                    showMacroView={showMacroView}
                                    setShowMacroView={setShowMacroView}
                                    toggleMiniView={toggleMiniView}
                                    showMiniView={showMiniView}
                                    miniViewMode3x3={miniViewMode3x3}
                                    toggleMiniViewMode={toggleMiniViewMode}
                                    tickerOptions={tickerOptions}
                            />
                            <a id={"HOME"} href={""}> </a>
                            <br/>
                            <br/>
                        </>
                        }


                        {tick.id != null && <div>

                            <ListsProvider activeTick={tick} setActiveTick={setTick}
                                           showMiniView={showMiniView} setShowMiniView={setShowMiniView}>
                                <TickerWatchListProvider activeTick={tick}>
                                    <ViewContextProvider tick={tick} brand={brand} >
                                        <ViewsSettingsModal/>
                                        <VPTGridProvider activeTick={tick}>
                                            <DashboardReportContextProvider
                                                brand={brand}
                                                tick={tick}
                                                customQueryList={showMiniView && miniViewMode3x3 ? COMPACT_VIEW_QUERY_LIST : showMiniView && !miniViewMode3x3 ? MINI_VIEW_REQUIRED_QUERIES : null}
                                                key={`${tick.id}-${brand.id}`}
                                                showCompetitors={true}
                                            >

                                                <CorrelationReportProvider
                                                    tick={tick}
                                                    showMiniView={showMiniView}
                                                >
                                                    <AltGtStackContextProvider activeTicker={tick.name}
                                                                               activeBrand={brand}
                                                                               queryType={DashboardQueryTypes.WEEKLY_DYN_ALT_GT_STACK}>
                                                        <DynSWStackProvider activeTicker={tick}>
                                                            <NavBar activeTick={tick}
                                                                    setActiveTick={setTick}
                                                                    activeBrand={brand}
                                                                    setActiveBrand={setBrand}
                                                                    showMacroView={showMacroView}
                                                                    setShowMacroView={setShowMacroView}
                                                                    toggleMiniView={toggleMiniView}
                                                                    showMiniView={showMiniView}
                                                                    tickerOptions={tickerOptions}
                                                                    scrollToItem={scrollToElement}
                                                                    toggleCorrelReportVisibility={toggleCorrelReportVisibility}
                                                                    miniViewMode3x3={miniViewMode3x3}
                                                                    toggleMiniViewMode={toggleMiniViewMode}
                                                            />
                                                            <WTDStacksComponent
                                                                brand={brand}
                                                                tick={tick}
                                                                wtdStackModalOpen={wtdStackModalOpen}
                                                                setWtdStackModalOpen={setWtdStackModalOpen}
                                                            />
                                                            <br/>
                                                            <br/>
                                                            {showMiniView && miniViewMode3x3 && <>
                                                                <DashboardReportCompactView tick={tick} brand={brand}
                                                                                            modifyTick={setTick}
                                                                                            setBrand={setBrand}/>
                                                            </>}
                                                            {showMiniView && !miniViewMode3x3 &&
                                                                <MiniView2x2 tick={tick} brand={brand}
                                                                             showMiniView={showMiniView}
                                                                             setBrand={setBrand}/>
                                                            }

                                                            {!showMiniView && !correlReportVisibility && <>
                                                                <VariableSizeList
                                                                    height={window.innerHeight}
                                                                    itemCount={fullDashBoardItemsList.length}
                                                                    itemSize={getItemSize}
                                                                    width={window.width}
                                                                    ref={listRef}
                                                                    overscanCount={3}
                                                                >
                                                                    {HeightObserverWrappedComponent}
                                                                </VariableSizeList>
                                                            </>
                                                            }
                                                            {!showMiniView && correlReportVisibility &&
                                                                <ContextConnectedCorrelationReport
                                                                    activeTicker={tick.name}
                                                                    activeBrand={brand.id}
                                                                    indexWeights={indexWeights}
                                                                    ccIndexWeights={ccIndexWeights}
                                                                />
                                                            }
                                                        </DynSWStackProvider>
                                                    </AltGtStackContextProvider>
                                                </CorrelationReportProvider>
                                            </DashboardReportContextProvider>
                                        </VPTGridProvider>
                                    </ViewContextProvider>
                                </TickerWatchListProvider>
                            </ListsProvider>

                        </div>}
                    </div>}
                    {auth != null && auth && showMacroView && <div>
                        <MacroReport
                            showMacroView={showMacroView}
                            setShowMacroView={setShowMacroView}
                        />
                    </div>}

                </div>}
            </RefreshContextProvider>
        </>
    )
}


export default DashboardReport;

//Todo: Fix the shortcuts to work
// Todo: Move correls report to a dedicated context provider and set it's rendering
//Todo: Remove all Modal based charts, and set them to be normal charts.