import {
    MdAppShortcut,
    MdChecklistRtl,
    MdCreditCard,
    MdDisabledVisible,
    MdInfo,
    MdKeyboardHide,
    MdLocalParking,
    MdMultilineChart,
    MdOutlineSearch,
    MdSplitscreen,
    MdWeb
} from "react-icons/md";
import {Button, Dropdown, Modal, Space, Tooltip} from "antd";
import {useState} from "react";
import {TfiYahoo} from "react-icons/tfi";

export const CC = "CC";
export const GT = "GT";
export const SW = "SW";
export const APP = "APP";
export const SEGMENT = "SEGMENT";
export const YIPIT = "YIPIT";
export const PLACER = "PLACER";
export const INDICES = "INDICES";

export const COMBINE_AXES = "COMBINE_AXES";

const SHORTCUT_ICON_MAP = {
    [CC]: <MdCreditCard/>,
    [GT]: <MdOutlineSearch/>,
    [SW]: <MdWeb/>,
    [APP]: <MdAppShortcut/>,
    [SEGMENT]: <MdSplitscreen />,
    [PLACER]: <MdLocalParking/>,
    [YIPIT]: <TfiYahoo />,
    [INDICES]: <MdMultilineChart />,
}

const SHORTCUT_TOOLTIP_MAP = {
    [CC]: "Press Ctrl+R to toggle CC series. Will also enable all weekly CC series, if showWeekly is active.",
    [GT]: "Press Ctrl+G to toggle GT series. Will also enable all weekly GT series, if showWeekly is active.",
    [APP]: "Press Ctrl+K to toggle APP(DAU & Download) series. Will also enable all weekly APP(DAU & Download) series, if showWeekly is active.",
    [SW]: "Press Ctrl+W to toggle Similar Web series. Will also enable the weekly series, if showWeekly is active.",
    [SEGMENT]: "Press Ctrl + E to toggle Segments series. Will also enable the weekly series, if showWeekly is active.",
    [PLACER]: "Press Ctrl + P to toggle Placer series. Will also enable the weekly series, if showWeekly is active.",
    [YIPIT]: "Press Ctrl + Y to toggle Yipit series. Will also enable the weekly series, if showWeekly is active.",
    [INDICES]: "Press Ctrl + X to toggle Indices series."
}

//Todo: add for indices shortcut, and for wtd charts:

const SHORTCUTS_DISPLAY_TABLE_1 = <table>
    <tbody>
    <tr>
        <td><h3>Watchlist / Positions</h3></td>
    </tr>
    <tr>
        <td>Go To Next</td>
        <td>Ctrl + .</td>
    </tr>
    <tr>
        <td>Go To Previous</td>
        <td>Ctrl + ,</td>
    </tr>
    <tr>
        <td><h3>Oracle</h3></td>
    </tr>
    <tr>
        <td>Toggle Stickiness of Series Config between tickers</td>
        <td>Ctrl + /</td>
    </tr>
    <tr>
        <td>Open Page wide controls pane</td>
        <td>Ctrl + \</td>
    </tr>
    <tr>
        <td>Drop Last Point</td>
        <td>Ctrl + ;</td>
    </tr>
    <tr>
        <td>Toggle Macro View</td>
        <td>Ctrl + M</td>
    </tr>
    <tr>
        <td>Toggle MiniView</td>
        <td>Ctrl + S</td>
    </tr>
    <tr>
        <td>Navigate to Chart</td>
        <td>See Subscripts in NavBar</td>
    </tr>
    <tr>
        <td><h3>Chart Series Click Modifiers</h3></td>
    </tr>
    <tr>
        <td>Disable all series except selected</td>
        <td>Z</td>
    </tr>
    <tr>
        <td>Toggle Y-Axis Visibility</td>
        <td>X</td>
    </tr>
    <tr>
        <td>Disable all Y-Axis</td>
        <td>C</td>
    </tr>
    <tr>
        <td>Toggle Series Across Charts</td>
        <td>V</td>
    </tr>
    <tr>
        <td><h3>Page Series Modifiers</h3></td>
    </tr>
    <tr>
        <td>Toggle all GT series</td>
        <td>Ctrl + G</td>
    </tr>
    <tr>
        <td>Toggle APP(DAU & Download) series</td>
        <td>Ctrl + K</td>
    </tr>
    <tr>
        <td>Toggle all Similar Web series</td>
        <td>Ctrl + W</td>
    </tr>
    <tr>
        <td>Toggle all Credit Card series</td>
        <td>Ctrl + R</td>
    </tr>
    <tr>
        <td>Toggle all Segment series</td>
        <td>Ctrl + E</td>
    </tr>
    <tr>
        <td>Toggle all Placer series</td>
        <td>Ctrl + P</td>
    </tr>
    <tr>
        <td>Toggle all Yipit series</td>
        <td>Ctrl + Y</td>
    </tr>
    <tr>
        <td><h3>Data Modifiers</h3></td>
    </tr>
    <tr>
        <td>Toggle Daily Stack Alignment</td>
        <td>Ctrl + D</td>
    </tr>
    <tr>
        <td>Toggle Peer Indices</td>
        <td>Ctrl + I</td>
    </tr>
    <tr>
        <td>Toggle Combine Axes across all charts</td>
        <td>Ctrl + O</td>
    </tr>
    <tr>
        <td><h3>Intraday Factors Returns</h3></td>
    </tr>
    <tr>
        <td>Toggle Live Data</td>
        <td>S</td>
    </tr>
    <tr>
        <td><h3>Compact View Modals (All wrap around)</h3></td>
    </tr>
    <tr>
        <td>Open Modal for Next Chart</td>
        <td>(Arrow Right)</td>
    </tr>

    <tr>
        <td>Open Modal for Previous Chart</td>
        <td>(Arrow Left)</td>
    </tr>
    <tr>
        <td>Open Modal for Chart Above</td>
        <td>(Arrow Up)</td>
    </tr>
    <tr>
        <td>Open Modal for Chart Below</td>
        <td>(Arrow Down)</td>
    </tr>
    </tbody>
</table>

const ShortcutItem = (toggleTriggerAndState, shortcutName, index, iconClassName) => {
    const IconComponent = <Button onClick={() => toggleTriggerAndState.trigger()}>{shortcutName}</Button>;
    const tooltipText = SHORTCUT_TOOLTIP_MAP[shortcutName];
    const toggleIndicatorElement = (toggleTriggerAndState?.state == null || toggleTriggerAndState.state) ?
        <MdDisabledVisible className={iconClassName}/> : <MdChecklistRtl className={iconClassName}/>;
    return {
        key: `${index}`, label:  <Space direction={"horizontal"}>
            <Tooltip title={tooltipText}><MdInfo style={{color: 'white'}}/> </Tooltip>{IconComponent}   {toggleIndicatorElement} <Button style={toggleTriggerAndState.excModeActive ? {color: 'blue'} : {} } onClick={() => toggleTriggerAndState.excModeFunction()}>Exc</Button> </Space>
    };
}


export const ShortcutsDropDown = ({toggleTriggersAndState, iconClassName, rootIconClassName}) => {
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
            setOpen(nextOpen);
        }
    };

    const disableModal = () => setShowModal(false);
    if (toggleTriggersAndState == null) return <></>
    const shortCutItems = Object.keys(SHORTCUT_ICON_MAP).map((shortcut, index) => ShortcutItem(toggleTriggersAndState[shortcut], shortcut, index, iconClassName));
    shortCutItems.push({
        key: `${shortCutItems.length}`,
        label: <Space direction={"horizontal"} onClick={() => setShowModal(true)}><span className={iconClassName}
                                                                                        style={{fontSize: 15}}>Show Shortcuts</span></Space>
    })
    return (<>
            <Dropdown menu={{items: shortCutItems}} open={open} onOpenChange={handleOpenChange}>
                <Space direction="horizontal" style={{color: 'white'}}>
                    <MdKeyboardHide className={rootIconClassName}/>
                </Space>
            </Dropdown>
            <Modal width={900} open={showModal} onOk={disableModal}
                   onCancel={disableModal}>{SHORTCUTS_DISPLAY_TABLE_1}</Modal>
        </>)
}
