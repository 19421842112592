import React, {useContext, useEffect, useState} from "react";
import StudioCandleChart from "../studio_high_chart/StudioCandleChart";
import FinMatrixGrid from "../findata/FinMatrixGrid";
import {Layout, Menu, Space, Tooltip} from "antd";
import {Header} from "antd/es/layout/layout";
import {Link} from "react-router-dom";
import {TickerDropdown} from "../oracle/components/TickerDropdown";
import TradingMultiplesCharts from "../line_chart/TradingMultiples";
import {ViewContextProvider} from "../oracle/DashboardReport/views/ViewContext";
import {computationService} from "../../api/Clients";
import {getTickers} from "../../api/data/DataProvider";
import {TICKER_NAME} from "../oracle/DashboardReport/DashboardConstants";
import {SeriesTypes} from "../../api/data/SeriesTypes";
import ErrorBoundary from '../tradingview/ExternalErrorBoundary';
import {StudioHistoricalPriceChart} from "../studio_high_chart/StudioHistoricalPriceChart";
import {StudioIntradayPriceChart} from "../studio_high_chart/StudioIntradayPriceChart";
import {VPTGrid} from "../vpt/VPTGrid";
import {VPTGridProvider} from "../vpt/VPTGridProvider";
import {FinDataGrid} from "../findata/FinDataGrid";
import {VPT_TABLE_MODES} from "../vpt/VptConfig";
import {HistoricalPriceDataProvider} from "../studio_high_chart/contextsForCharts/HistoricalPriceDataProvider";
import {IntradayStockPriceDataProvider} from "../studio_high_chart/contextsForCharts/IntradayStockPriceWorker";
import {FinancialMatricesDataProvider} from "../oracle/findata/FinancialMatricesDataProvider";
import {TradingMultiplesDataProvider} from "../oracle/findata/TradingMultiplesDataProvider";
import {RefreshContext, RefreshContextProvider} from "../oracle/commonContexts/RefreshContextProvider";
import {MdRefresh, MdBlurCircular} from "react-icons/md";
import {AiOutlineFundView} from "react-icons/ai";

import {
    DASHBOARDS_PATH,
    FACTORS_PATH,
    FINDATA_PATH,
    INTRADAY_PATH,
    LINKEDIN_PATH,
    MACRO_PATH,
    NAV_PATH,
    POSITIONS_PATH
} from "../oracle/PathConstants";


const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({behavior: 'instant', block: 'center'});
    }
}

export const fetchVptData = async (tickerId) => {
    const response = await computationService.get(`/v1/multiples/ticker/vpt?tickerId=${tickerId}`);
    return response.data;
}


function FinDataHeader(props) {

    const {toggleRefresh} = useContext(RefreshContext);
    
    return <Header style={{
        height: 45,
        position: "fixed",
        padding: "0 10px",
        width: "100%",
        zIndex: 1000,
        marginBottom: 200
    }}>
        <Menu selectable={false} mode={"horizontal"} theme={"dark"}
              style={{
                  height: 45,
                  alignItems: "center",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center"
              }}>
            <Menu.Item key="1"
                       onClick={() => scrollToElement("findataGrid")}>Grid<sub>a</sub></Menu.Item>
            <Menu.Item key="2"
                       onClick={() => scrollToElement("vpt")}>VPT<sub>s</sub></Menu.Item>
            <Menu.Item key="3"
                       onClick={() => scrollToElement("multiplesCharts")}>Multiples <sub>d</sub></Menu.Item>
            <Menu.Item key="4"
                       onClick={() => scrollToElement("surprisesCharts")}>Surprises <sub>f</sub></Menu.Item>
            <Menu.Item key="5"
                       onClick={() => scrollToElement("financialMatrix")}>Matrices <sub>g</sub></Menu.Item>
            <Menu.Item key="6"
                       onClick={() => scrollToElement("historicalPriceChart")}>Historical <sub>h</sub></Menu.Item>
            <Menu.Item key="7"
                       onClick={() => scrollToElement("intradayPriceChart")}>Intraday <sub>j</sub></Menu.Item>
            <Menu.Item disabled>
                <div style={{display: "flex"}}>
                    {props.tickerOptions && props.tickerOptions.length > 0 ?
                        <TickerDropdown activeTick={props.activeTicker || {name: "", id: null}}
                                        setActiveTick={props.setActiveTick}
                                        tickerOptions={props.tickerOptions}
                                        setActiveBrand={props.activeBrand}
                                        setShowMacroView={props.activeBrand}
                        /> : "Loading.."}
                </div>
            </Menu.Item>
            <Menu.Item
                style={{background: "transparent"}}
                onClick={toggleRefresh}
            >
                <Space direction="horizontal" style={{color: "white"}}>
                    <Tooltip title="Click to refresh all data.">
                        <MdRefresh style={{color: "white", fontSize: 25}}

                        />
                    </Tooltip>
                </Space>
            </Menu.Item>
            <Menu.SubMenu
                        key={'NAVIGATION_SUBMENU'}
                        title={
                            <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}><AiOutlineFundView
                                style={{fontSize: 25, color: "#ffffff"}}/></Link>
                        }
                    >
                        <Menu.Item>
                            <Link to={NAV_PATH} target={"_blank"} style={{textDecoration: "none"}}>NAV
                                Screen</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={POSITIONS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Positions</Link>
                        </Menu.Item>

                        <Menu.Item>
                            <Link to={DASHBOARDS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Dashboard</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={FACTORS_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Factors</Link>
                        </Menu.Item>
                        
                        <Menu.Item>
                            <Link to={INTRADAY_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Intraday</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={MACRO_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>Macro</Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={LINKEDIN_PATH} target={"_blank"}
                                  style={{textDecoration: "none"}}>LinkedIn</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
            <Menu.Item
                style={{background: 'transparent'}}
                disabled={!props.activeTick}
            >
                <Space direction="horizontal" style={{color: 'white'}}>
                    <Tooltip title="Go to oracle for ticker">
                        <Link 
                            to={props.activeTicker ? `/?tickerName=${props.activeTicker.name}` : '#'}
                            target="_blank"
                            style={{textDecoration: "none", pointerEvents: props.activeTicker ? 'auto' : 'none'}}
                        >
                            <MdBlurCircular style={{fontSize: 25, color: props.activeTicker ? 'white' : 'gray'}} />
                        </Link>
                    </Tooltip>
                </Space>
            </Menu.Item>
        </Menu>

    </Header>;
}

const FinDataPage = () => {
    const [activeTicker, setActiveTicker] = useState(null);
    let [tickerOptions, setTickerOptions] = useState([]);
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const tickerNameFromUrl = searchParams.get(TICKER_NAME);

        if (activeTicker) {
            // Ensure URL matches active ticker
            if (activeTicker.name !== tickerNameFromUrl) {
                searchParams.set(TICKER_NAME, activeTicker.name);
                const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
                window.history.replaceState(null, '', newUrl);
            }
        } else if (tickerNameFromUrl && tickerOptions.length > 0) {
            // Set active ticker from URL if not already set
            const tickerOption = tickerOptions.find(t => t.label === tickerNameFromUrl);
            if (tickerOption) {
                setActiveTicker({name: tickerNameFromUrl, id: tickerOption.value});
            }
        }
    }, [activeTicker, tickerOptions]);

    useEffect(() => {
        getTickers().then((res) => {
            let activeTickers = res.data.map(tickResponse => {
                return {
                    value: tickResponse.tickerId,
                    label: tickResponse.tick
                }
            });
            setTickerOptions(activeTickers)
        }).catch((err) => {
            console.error("Error fetching Tickers", err)
        });
    }, []);


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (window.isEnableHotKeys && !event.ctrlKey) {
                if (event.key === 'a') {
                    scrollToElement("findataGrid");
                } else if (event.key === 's') {
                    scrollToElement("vpt");
                } else if (event.key === 'd') {
                    scrollToElement("multiplesCharts");
                } else if (event.key === 'f') {
                    scrollToElement("surprisesCharts");
                } else if (event.key === 'g') {
                    scrollToElement("financialMatrix");
                } else if (event.key === 'h') {
                    scrollToElement("historicalPriceChart");
                } else if (event.key === 'j') {
                    scrollToElement("intradayPriceChart");
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    return (
        <div>
            <RefreshContextProvider>
                <HistoricalPriceDataProvider activeTicker={activeTicker}>
                    <IntradayStockPriceDataProvider activeTicker={activeTicker}>
                        <Layout style={{height: 45, marginBottom: 20}}>
                            <FinDataHeader
                                           tickerOptions={tickerOptions}
                                           activeTicker={activeTicker}
                                           setActiveTick={(ticker) => {
                                               setActiveTicker(ticker);
                                           }} activeBrand={() => {
                            }} />
                        </Layout>
                        {activeTicker && <div>
                            <h2>{activeTicker.name}</h2>
                            <ViewContextProvider tick={activeTicker.name}>
                                <ErrorBoundary>
                                    <VPTGridProvider activeTick={activeTicker}>
                                        <div id={'findataGrid'}>
                                            <FinDataGrid/>
                                        </div>
                                        <div id={'vpt'}>
                                            <VPTGrid onlyGridView={false} gridWidth={'100%'}
                                                     defaultMode={VPT_TABLE_MODES.FULL}/>
                                        </div>
                                    </VPTGridProvider>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <div id="multiplesCharts">
                                        <TradingMultiplesDataProvider activeTicker={activeTicker}>
                                            <TradingMultiplesCharts
                                                activeTicker={activeTicker.id}
                                            />
                                        </TradingMultiplesDataProvider>
                                    </div>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <div id="surprisesCharts">
                                        {
                                            [SeriesTypes.P_EPS, SeriesTypes.EV_EBITDA, SeriesTypes.EV_SALES].map((seriesType) => {
                                                return (
                                                    <div id={seriesType + "_surpriseChart"} style={{marginTop: 40}}
                                                         key={seriesType}>
                                                        <StudioCandleChart activeTicker={activeTicker.id}
                                                                           ratio={seriesType}/>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <div id="financialMatrix" style={{marginTop: 40, marginBottom: 50}}>
                                        <h3>Financial Matrices</h3>
                                        <FinancialMatricesDataProvider activeTicker={activeTicker}>
                                            <FinMatrixGrid/>
                                        </FinancialMatricesDataProvider>
                                    </div>
                                </ErrorBoundary>
                            </ViewContextProvider>
                            <div id={'historicalPriceChart'}>
                                <StudioHistoricalPriceChart/>
                            </div>
                            <div id={'intradayPriceChart'}>
                                <StudioIntradayPriceChart/>
                            </div>

                        </div>}
                    </IntradayStockPriceDataProvider>
                </HistoricalPriceDataProvider>
            </RefreshContextProvider>
        </div>
    );
}

export default FinDataPage;