import {SplitContextsConsumerWrapper} from "../DashboardReport/views/ViewContext";
import {getChartForQueryType, getYearsToShowForQueryType} from "../../../api/data/QueryTypeToChartMapping";
import {useRef} from "react";
import Measure from "react-measure";
import {DashboardQueryTypes, VPT_GRID} from "../../../api/data/DashboardQueryTypes";
import {VPTGrid} from "../../vpt/VPTGrid";

const ESTIMATED_INITIAL_HEIGHT = 300;


export const ChartGrid = ({queryList, brand, tick, refreshTrigger}) =>  {


    const chartHeight = useRef(ESTIMATED_INITIAL_HEIGHT);
    const chartWidth = useRef(ESTIMATED_INITIAL_HEIGHT);

    const getChartForIndex = (index) => {
        const query = queryList[index];
        if(DashboardQueryTypes[query] != null)
            return < SplitContextsConsumerWrapper
                    key={`ChartGrid-SplitConsumer-${index}`}
                    queryType={query}
                    RawComponent={getChartForQueryType(query)}
                    activeTicker={tick?.name}
                    activeBrandName={brand?.name}
                    activeBrand={brand.id}
                    chartHeightOverride={chartHeight.current+'px'}
                    chartWidth={chartWidth.current+'px'}
                    yearsToShow={getYearsToShowForQueryType(query)}
                    isMiniMode={true}
            />
        else if (query === VPT_GRID){
            return <VPTGrid
                        onlyGridView={true}
                        gridHeight={chartHeight.current}
                        gridWidth={chartWidth.current}
                    />
        }
    }

    //Since All charts are same height, we can use a single ref here

    const HeightObserverWrappedGridChart = (index) => {
        return < Measure
            bounds
            onResize={(contentRect) => {
                chartHeight.current = contentRect.bounds.height;
                chartWidth.current = contentRect.bounds.width;
            }}>
            {
                ({measureRef}) => <div ref={measureRef} key={`ChartGrid-Chart-${index}`} className="grid-chart-container">
                    {getChartForIndex(index)}
                </div>
            }
        </Measure>
    }

    return (
        <>
            {tick?.id == null &&<><h3>Select a Watchlist / Position to continue.</h3> <h3>Use (CTRL + ,) & (CTRL + .) to navigate once a list is selected.</h3></> }
            { tick?.id != null &&<div className="grid-container">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(index => HeightObserverWrappedGridChart(index) )}
            </div>}
        </>
    )
}