import {numberValueFormatter} from "../../../../utils/StudioAgGridUtils";

export const columnDefs = [
    {
        headerName: 'Ticker',
        field: 'combinedTicker',
        cellStyle: {"vertical-align": "middle"},
        filter: true,
        width: 140,
        sortable: true,
        aggFunc: () => "TOTAL"
    },
    {
        headerName: '% of Net Liq',
        field: 'ratioOfNav',
        cellStyle: {textAlign: 'right'},
        cellRenderer: ({value}) => {
            if (!value) {
                return <></>;
            }
            let netLiqPercentage = (value * 100).toFixed(1);
            let color = (value >= 0) ? "green" : "red";
            return <span style={{color: color}}>
                {netLiqPercentage} %
            </span>;
        },
        width: 120,
        filter: true,
        sortable: true,
        sort: 'desc'
    },
    {
        headerName: 'Quantity',
        field: 'position',
        cellStyle: {textAlign: 'right'},
        width: 100,
        filter: true,
        sortable: true
    },
    {
        headerName: 'Mkt Value',
        field: 'currentMarketValue',
        cellStyle: {textAlign: 'right'},
        width: 140,
        cellRenderer: ({value}) => {
            return <span>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    maximumFractionDigits: 0,
                    currency: 'USD'
                }).format(value)}
            </span>;
        },
        filter: true,
        sortable: true,
        aggFunc: "sum",
    },
    {
        headerName: 'Unrealized PnL',
        field: 'unrealizedPnl',
        cellStyle: {textAlign: 'right'},
        cellRenderer: ({value}) => {
            if (!value) {
                return <></>;
            }
            let unrealizedPnl;
            try {
                unrealizedPnl = value?.toFixed(0);
            } catch (err){
                console.log("Error for value: ", value);
            }
            let color = (value >= 0) ? "green" : "red";
            return <span style={{color: color}}>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    maximumFractionDigits: 0,
                    currency: 'USD'
                }).format(unrealizedPnl)}
            </span>;
        },
        width: 140,
        sortable: true,
        aggFunc: "sum",
    },
    {
        headerName: 'Daily PnL',
        field: 'dailyPnl',
        cellStyle: {textAlign: 'right'},
        cellRenderer: ({value}) => {
            if (null !== value
                && undefined !== value
                && typeof value === 'number') {
                let dailyPnl = value?.toFixed(0);
                let color = (value >= 0) ? "green" : "red";
                return <span style={{color: color}}>
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        maximumFractionDigits: 0,
                        currency: 'USD'
                    }).format(dailyPnl)}
                </span>;
            } else {
                return '';
            }
        },
        width: 140,
        sortable: true,
        aggFunc: "sum",
    }
];

export const intradayColumnDefs = [
    ...columnDefs.filter(col => !col.headerName.includes("PnL")),
    {
        headerName: 'Beta',
        field: 'stockPosition.beta',
        cellStyle: {textAlign: 'right'},
        valueFormatter: numberValueFormatter(2),
        width: 80,
        filter: true,
        sortable: true,
    },
    {
        headerName: 'Beta Adj Delta Dollars',
        field: 'betaAdjustedDeltaDollars',
        cellStyle: {textAlign: 'right'},
        width: 140,
        cellRenderer: ({value}) => {
            return <span>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    maximumFractionDigits: 0,
                    currency: 'USD'
                }).format(value)}
            </span>;
        },
        filter: false,
        sortable: true,
        aggFunc: "sum",
    },
];