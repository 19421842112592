export const getIndex = (scrollTicks, activeTick) => {
    if (scrollTicks && scrollTicks.length > 0) {
        const isObjectArray = typeof scrollTicks[0] === 'object';
        const targetValue = activeTick?.id;

        // Using findIndex to get the index
        const index = scrollTicks.findIndex(item =>
            isObjectArray ? item.tickerId === targetValue : item === targetValue
        );

       // Return -1 if not in list, else fix to a 1-based index:
        return index >= 0 ? index + 1 : -1;
    }

    // The array is not defined or is empty
    return -1;
}

export const washSaleRenderingComponent = (washSaleInfo) => {
    if (washSaleInfo === undefined) {
        return <span>Loading...</span>;
    }

    if (washSaleInfo === null) {
        return <span>WASH ERROR</span>;
    }

    if (washSaleInfo.length === 0) {
        return <span style={{color: "green"}}>NO WASH</span>;
    }

    let washEndDate = washSaleInfo[0].washsaleEndDate;
    let washLoss = washSaleInfo[0].washsaleLoss;

    return washEndDate && washLoss && <span>WASH: <span style={{"color": "red"}}>${(washLoss*-1/1000).toFixed(0)}K</span><span style={{marginLeft: 5}}>({washEndDate})</span></span>;
}

export const getSiPctFloat = (shortInterestInfo) => {
    if (shortInterestInfo != null) {
        let siPctFloat = shortInterestInfo.filter(info => info.shortInterestType === "PCT_FLOAT")
        if (siPctFloat.length > 0) {
            return {
                value: siPctFloat[0].shortInterestPct,
                oneMonthChg: siPctFloat[0].oneMonthChange,
                asOf: siPctFloat[0].asOfDate
            }
        }
    }

    return {
        value: "N/A",
        oneMonthChg: "N/A",
        asOf: "N/A"
    };
}

export const resolveColor = (value) => {
    if (value === "N/A") {
        return "black";
    }

    if (value < 0) {
        return "green";
    }

    if (value > 0) {
        return "red";
    }
}

export const getShortInterestComponent = (shortInterestInfo) => {
    let siPctFloat = getSiPctFloat(shortInterestInfo);
    let siPctShout = getSiPctShout(shortInterestInfo);

    return <span style={{ color: "#000000", fontWeight: 500 }}>
                                <span style={{ fontWeight: 300 }}>SI Float:</span> <span>{siPctFloat.value === "N/A" ? "N/A" : (siPctFloat.value * 100).toFixed(1)}%</span>
                                ,
                                <span style={{ color: resolveColor(siPctFloat.oneMonthChg) }}>{siPctFloat.oneMonthChg === "N/A" ? "N/A" : (siPctFloat.oneMonthChg * 100).toFixed(1)}%</span> 30D Chg
                                <span style={{ marginLeft: 5 }}>({siPctFloat.asOf})</span>
                                <span style={{
                                    marginLeft: 30,
                                    fontWeight: 300
                                }}> SI S/O:</span> <span>{siPctShout.value === "N/A" ? "N/A" : (siPctShout.value * 100).toFixed(1)}%</span>
                                ,
                                <span
                                    style={{ color: resolveColor(siPctShout.oneMonthChg) }}>{siPctShout.oneMonthChg === "N/A" ? "N/A" : (siPctShout.oneMonthChg * 100).toFixed(1)}%</span> 30D Chg
                                <span style={{ marginLeft: 5 }}>({siPctShout.asOf})</span>
                            </span>
}

export const getCompanyProfileInfoComponent = (profileInfo) => {
    if (profileInfo == null) {
        return <span><b>NOT AVAILABLE</b></span>
    }

    const mktCapInBillion = profileInfo.mktCap / 1_000_000_000;

    return <span style={{ color: "#000000", fontWeight: 500 }}>
        <span>Avg. Vol: <b>{abbreviateNumber(profileInfo.volAvg)}</b></span>
        <span style={{ marginLeft: 10 }}>Mkt. Cap: <b>{mktCapInBillion.toFixed(2)} B</b></span>
    </span>
}

const abbreviateNumber = (num) => {
    if (num >= 1_000_000) {
        return (num / 1_000_000).toFixed(1) + 'M';
    } else if (num >= 1_000) {
        return (num / 1_000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
};

export const getSiPctShout = (shortInterestInfo) => {
    if (shortInterestInfo != null) {
        let siPctShout = shortInterestInfo.filter(info => info.shortInterestType === "PCT_SHARES_OUTSTANDING")
        if (siPctShout.length > 0) {
            return {
                value: siPctShout[0].shortInterestPct,
                oneMonthChg: siPctShout[0].oneMonthChange,
                asOf: siPctShout[0].asOfDate
            }
        }
    }

    return {
        value: "N/A",
        oneMonthChg: "N/A",
        asOf: "N/A"
    };
};