export const SeriesTypes = {
    PRICE: 'PRICE',
    EV: 'EV',
    EBITDA: 'EBITDA',
    EPSDILUTED: 'EPSDILUTED',
    PAT: 'PAT',
    FCF: 'FCF',
    SALES: 'SALES',
    MCAP: 'MARKETCAP',
    GROSSPROFIT: 'GROSSPROFIT',
    GROSSMARGIN: 'GROSSMARGIN',
    REPORTINGDATES: 'REPORTINGDATES',
    P_EPS: 'P_EPS',
    EV_EBITDA: 'EV_EBITDA',
    EV_EBIT: 'EV_EBIT',
    EV_SALES: 'EV_SALES',
    P_SALES: 'P_SALES',
    C_PRICEREACTION: 'PRICEREACTION',
    COGS: 'COGS',
    IS_DIVIDENDSPERSHARE: 'DIVIDENDSPERSHARE',
    FCF: 'FCF',
    DnA: 'DnA',
    SGnA: 'SGnA',
    SALESPERSHARE: 'SALESPERSHARE',
}


